<template>
  <v-app>
    <Header header-color="white" />
    <v-main class="main-style">
      <slot />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Header from "@/components/shared/Header.vue";
import Footer from "@/components/shared/FooterSmall.vue";
export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style scopped>
.main-style {
  background: rgb(114, 48, 127);
  background: radial-gradient(
    circle,
    rgba(114, 48, 127, 0.9360119047619048) 0%,
    rgba(0, 0, 0, 1) 49%
  );
}
@media screen and (max-width: 600px) {
  .main-style {
    background: rgb(114, 48, 127);
    background: radial-gradient(
      circle,
      rgba(114, 48, 127, 0.9724264705882353) 46%,
      rgba(0, 0, 0, 1) 91%
    );
  }
}
</style>
