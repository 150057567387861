/* eslint-disable no-extra-boolean-cast */
export default function validations(label, rules) {
  let validations = [];

  for (var key in rules) {

    switch (key) {
      case "required":
        validations.push(validateRequired.bind(null, rules[key], label));
        break;
      case "email":
        validations.push(validateEmailId.bind(null, rules[key], label));
        break;
      case "maxlength":
        validations.push(validateMaxLength.bind(null, rules[key], label));
        break;
      case "min":
        validations.push(validateMin.bind(null, rules[key], label));
        break;
      case "max":
        validations.push(validateMax.bind(null, rules[key], label));
        break;
      case "regex":
        validations.push(validateRegex.bind(null, rules[key], label));
        break;
      case "zip":
        validations.push(validateZIP.bind(null, rules[key], label));
        break;
      case "phone":
        validations.push(validatePhone.bind(null, rules[key], label));
        break;
      case "ssn":
        validations.push(validateSSN.bind(null, rules[key], label));
        break;
    }
  }

  return validations;
}

function validateRequired(ruleValue, label, v) {
  if (!!v) return true;
  return `${label} is required`;
}

function validateRegex(ruleValue, label, v) {
  if (v && ruleValue.test(v)) return true;
  return `Invalid ${label}`;
}

function validateZIP(ruleValue, label, v) {
  if (v && /^\d{5}(-\d{4})?$/.test(v)) return true;
  return `Invalid ${label}`;
}

function validatePhone(ruleValue, label, v) {
  if (v && /^(\([2-9][0-9]{2}\) | [2-9][0-9]{2}-)[2-9][0-9]{2}-[0-9]{4}$/.test(v)) return true;
  return `Invalid ${label}`;
}

function validateMaxLength(ruleValue, label, v) {
  if (v != undefined && v.toString().length > 0) return true;
  return `${label} must be less than  ${ruleValue} characters`
}

function validateMin(ruleValue, label, v) {
  if (isNaN(v)) {
    v = parseFloat(v.replace(/\$/g, ''));
  }
  if (v && v >= ruleValue) return true
  return `Minimum is ${ruleValue} `
}

function validateSSN(ruleValue, label, v) {
  if (v && v.length == 11 && /^(?!\b(\d)\1+-(\d)\1+-(\d)\1+\b)(?!123-45-6789|219-09-9999|078-05-1120)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$|^XXX-XX-(?!0{4})\d{4}$/.test(v)) return true
  return `Invalid ${label}`;
}

function validateEmailId(ruleValue, label, v) {
  if (v && /.+@.+\..+/.test(v)) return true
  return `Email Id must be valid`;
}


function validateMax(ruleValue, label, v) {
  if (v && v <= ruleValue) return true
  return `Maximum is ${ruleValue} `
}

