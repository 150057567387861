<template>
  <div class="all">
    <div class="homebg">
      <v-row class="pt-8" no-gutters>
        <v-col cols="1" md="4" lg="4" class="d-flex justify-end align-end" style="position: relative">
          <!-- <div class=" h-full justify-end ">   -->
          <div width="46" class="mb-16 startleft">
            <v-img :src="require('@/assets/dream-art/star3.svg')" height="46" width="46" class="star"></v-img>
            <!-- </div> -->
          </div></v-col
        >
        <v-col cols="10" md="4" lg="4">
          <div class="d-flex justify-end">
            <div width="71" class="mr-16">
              <v-img class="mr-16 star" :src="require('@/assets/dream-art/star1.svg')" width="71"></v-img>
            </div>
          </div>
          <div class="justify-center">
            <v-img :src="require('@/assets/dream-art/swapnalogo.png')"></v-img>
          </div>
          <div class="pt-9" style="position: relative">
            <p class="subtext">Celebration of women's dreams,</p>
            <p class="subtext">Unique collection of digital artwork inspired by women's dreams</p>
          </div>
        </v-col>
        <!-- <v-col cols="1" lg="4"></v-col> -->
        <v-col cols="1" lg="4" class="d-flex justify-start align-end" style="position: relative">
          <div width="72" class="mb-8 startright">
            <v-img class="star" :src="require('@/assets/dream-art/star2.svg')" width="72"></v-img>
          </div>
        </v-col>
      </v-row>
      <div v-if="issocialday" class="pt-10">
        <div class="d-flex justify-center align-center ismobile">
          <div class="block">
            <p class="digit">30k+</p>
            <p class="text">Collectables</p>
          </div>
          <v-divider class="vertline mx-14 my-auto" vertical />
          <div class="block text-center">
            <p class="digit">{{ dreamverseDays }}</p>
            <p class="text">Days</p>
          </div>
          <v-divider class="vertline mx-14 my-auto" vertical />
          <div class="block text-right">
            <p class="digit">{{ collectedDreamArtCount }} +</p>
            <p class="text">Collected Artwork</p>
          </div>
        </div>
      </div>
      <div v-else class="d-flex justify-center justify-space-around pt-10">
        <Countdown date="2023-03-21"></Countdown>
      </div>
      <v-row no-gutters class="py-12">
        <v-col lg="4" md="4" cols="12"> </v-col>
        <v-col lg="4" md="4" cols="12" class="pb-12 d-flex justify-space-around">
          <v-btn
            tile
            default
            color="buttonBackground"
            class="buttonText--text text-none txtStyle elevation-0"
            @click="goToClaim()"
            >Claim Your Dream Art
            <v-img class="ml-6" height="16" :src="require('@/assets/arrow.png')"></v-img>
          </v-btn>
        </v-col>
        <v-col lg="4" md="4" cols="12"> </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" no-gutters class="mt-6">
          <div class="background mb-md-10">
            <div class="bgimage">
              <v-img :src="require('@/assets/dream-art/strip1.svg')" class="formobile"></v-img>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div style="position: relative">
      <div class="collect" style="position: relative">
        <div class="container">
          <v-row class="collectionrows">
            <v-col cols="12" lg="5" sm="12" class="justify-top">
              <v-img :src="require('@/assets/dream-art/frame.png')" contain></v-img>
            </v-col>
            <v-col cols="12" lg="7" class="text-left" style="position: relative">
              <!-- <div class="framecol">
    <div class="framecol1"> -->
              <div class="mt-lg-12" style="position: relative">
                <p class="frametitle title--text">Swapna Dreamverse</p>
                <p class="frametitle title--text mb-4">Collections.</p>
                <p class="desc description--text pb-4">
                  Unique collection of digital artwork inspired by women's dreams. Our collection features a variety of
                  dreamy and surreal artwork that captures the essence of the dreams and visions that women experience.
                </p>
                <p class="desc description--text pb-4">
                  Clement Morin created the foundational artwork, SoulScapes - A Whirlwind of Emotions. Specifically,
                  the acquired artwork was enhanced to form a "Dream Collection" that symbolizes the dreams of women.
                </p>
                <p class="desc description--text">
                  At Swapna Dreamverse Collections, we're passionate about empowering women and celebrating their
                  dreams. We believe that by showcasing the beauty and power of women's dreams, we can inspire and
                  uplift women everywhere.
                </p>
                <p class="dreamleft white--text pt-4">{{ remainingDreamCount }} dreams left</p>
                <v-row no-gutters class="mt-8">
                  <v-col lg="6" md="6" cols="12" class="claimbtncenter">
                    <v-btn
                      tile
                      default
                      color="buttonBackground"
                      class="buttonText--text text-none txtStyle elevation-0"
                      @click="goToClaim()"
                      >Claim Your Dream Art
                      <v-img class="ml-6" height="16" :src="require('@/assets/arrow.png')"></v-img>
                    </v-btn>
                  </v-col>
                </v-row>
                <!-- </div>
</div> -->
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="container">
        <div class="d-flex justify-space-around mt-lg-n16 pt-lg-n16 pt-md-12">
          <v-img :src="require('@/assets/dream-art/hrline.png')" cover max-width="75%"></v-img>
        </div>
      </div>
    </div>

    <div class="container" style="position: relative">
      <div class="text-left" style="position: relative">
        <div class="d-flex flexmob justify-space-between" style="position: relative">
          <div class="join d-flex flex-column">
            <p class="title--text">Let's join movement to see</p>
            <p class="title--text">the world of dreams.</p>
          </div>
          <div class="d-flex playmobile justify-center" @click="dialog = true">
            <div class="float-right white--text d-flex flex-column play">
              <p class="white--text">play</p>
              <p class="white--text">promo video</p>
            </div>
            <div class="ml-4">
              <v-icon size="70" color="#FFFFFF" class="pointer">mdi-play-circle</v-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="roadmap">
        <div class="shade">
          <div class="roadmap1 mb-16">
            <p class="title--text roadtitle">Roadmap</p>
            <div class="d-flex justify-space-around">
              <v-img :src="require('@/assets/dream-art/hrline.png')" cover max-width="75%"></v-img>
            </div>
            <div class="d-flex justify-space-around mb-16">
              <p class="description--text roaddesc mt-8">
                This free drop features various utility initiatives and futures drops from other artists keeping the
                Women's Dream and The Vision of SWAPNA to stop women abuse in mind.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" style="position: relative">
      <v-carousel class="myCarousel" hide-delimiters>
        <template v-for="(item, index) in slider">
          <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="index">
            <v-row class="flex-nowrap" style="height: 300px">
              <template v-for="(n, i) in columns">
                <template v-if="+index + i < slider.length">
                  <v-col :key="i" class="mr-md-6 mr-lg-6">
                    <v-sheet v-if="+index + i < slider.length" color="transparent" height="100%">
                      <v-row class="fill-height" v-if="slider[index + i].title != ''">
                        <div class="mt-4 text-left fill-height col">
                          <v-img :src="require('@/assets/dream-art/steppericon.svg')" contain></v-img>
                          <div class="cardbg fill-height mt-5 conic">
                            <p class="cardtitle pt-8 mx-6">
                              {{ slider[index + i].title }}
                            </p>
                            <p class="carddesc mt-6 mx-6">
                              {{ slider[index + i].desc }}
                            </p>
                          </div>
                        </div>
                      </v-row>
                    </v-sheet>
                  </v-col>
                </template>
              </template>
            </v-row>
          </v-carousel-item>
        </template>
        <template v-slot:prev="{ on, attrs }">
          <v-btn tile v-bind="attrs" v-on="on" class="leftbtnbg">
            <v-img contain :src="require('@/assets/dream-art/left.png')" height="16px"></v-img>
          </v-btn>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-btn tile color="white" v-bind="attrs" v-on="on" class="rightbtnbg">
            <v-img contain :src="require('@/assets/dream-art/right.png')" height="16px"></v-img>
          </v-btn>
        </template>
      </v-carousel>
    </div>
    <v-dialog v-model="dialog" max-width="860px" class="elevation-0">
      <iframe
        width="560"
        height="470"
        :src="
          'https://www.youtube.com/embed/3SxdsOVcfow?rel=0&modestbranding=0&autohide=1&showinfo=0&controls=1&autoplay=' +
          isAutoPlay
        "
        frameborder="0"
        allow=" autoplay;encrypted-media;picture-in-picture;"
        allowfullscreen
      ></iframe>
    </v-dialog>
  </div>
</template>
<script>
import Countdown from "@/components/Countdown.vue"
import api from "@/services/api"

export default {
  components: {
    Countdown,
  },
  methods: {
    goToClaim() {
      if (this.islogged) {
        this.$router.push("/reveal-dream")
      } else {
        this.$router.push("/login-or-register")
      }
    },
  },

  computed: {
    isAutoPlay() {
      return this.dialog ? 1 : 0
    },
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 4
      }

      if (this.$vuetify.breakpoint.lg) {
        return 3
      }

      if (this.$vuetify.breakpoint.md) {
        return 2
      }

      return 1
    },
    issocialday() {
      if (new Date(this.date).getTime() >= new Date(this.socialDate).getTime()) {
        return true
      } else {
        return false
      }
    },
  },
  async created() {
    let count = await api.getDreamCollectionCount()
    this.remainingDreamCount = 27800 - parseInt(count.data.data)
    this.collectedDreamArtCount = 30000 - this.remainingDreamCount
    const timeDiff = new Date().getTime() - new Date("2023-03-21").getTime()
    this.dreamverseDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
  },
  data: () => ({
    islogged: sessionStorage.getItem("isLogged"),
    isClaimed: sessionStorage.getItem("isClaimed"),
    dialog: false,
    date: new Date().toISOString().substr(0, 10),
    socialDate: new Date("2023-03-21"),
    toggle: false,
    model: null,
    remainingDreamCount: 30000,
    collectedDreamArtCount: "",
    dreamverseDays: "",
    slider: [
      {
        title: "Phase 1",
        desc: "Convert the current dream collection  into a digital assets and enable trading on marketplaces.",
      },
      {
        title: "Phase 2",
        desc: 'Offer future collection drops for "Dream Collection" holders.',
      },
      {
        title: "Phase 3",
        desc: "Launch collective fundraise initiatives to help women who are victims of abuse.",
      },
      {
        title: "Phase 4",
        desc: 'Create an exclusive community for "Dream Collection" holders to define governance around justice for abused women.',
      },
      {
        title: "Phase 5",
        desc: 'Launch "Dream Collection" merchandise exclusively for "Dream Collection" holders to purchase and share with friends and family.',
      },
      {
        title: "Phase 6",
        desc: "Organize local and online events to educate communities on preventing women abuse and helping women rebuild their lives.",
      },
      {
        title: "",
        desc: "",
      },
      {
        title: "",
        desc: "",
      },
    ],
  }),
}
</script>
<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
.background {
  position: relative;

  // transform: rotate(-358deg);
  z-index: 10;

  // margin-bottom: 50px;
}
.bgimage {
  width: 100% !important;
  position: absolute;
  transform: rotate(-358deg);
}
.collection {
  position: relative;
  // min-height: 80vh;

  background-color: #0b0b09;
  z-index: 0;
  // margin-top: -70px;
}

.img1n2 {
  position: relative;
  display: flex;
  justify-content: left;
}

.img1 {
  z-index: 1;
  height: 650px;
  width: 100%;
  object-position: left bottom;
}

.img2 {
  position: absolute;
  z-index: 2;
  right: 10%;
  top: 20%;
}
.parent {
  position: absolute;
  top: 0;
  left: 0;
}
.image1 {
  position: relative;
  top: 0;
  left: 0;
}
.image2 {
  position: absolute;
  left: 10%;
  right: 10%;
  top: 50;
}

.letsjoin {
  // position: relative;
  width: 80%;
  display: block;
  //  justify-items: center;
  background-color: #0b0b09;
  z-index: 0;
}

.all {
  background-color: #0b0b09;
}
.subtext {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  /* or 25px */

  text-align: center;

  color: #ffffff;
}
.txtStyle {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  // padding-left: 24px !important;
  // padding-right: 24px !important;
  // padding-top: 16px !important;
  // padding-bottom: 16px !important;
}
::v-deep .v-btn {
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.btnBorder1 {
  border: none !important;
}
.top-square {
  position: absolute;
  top: 0%;

  height: 80%;
  width: 80%;
  background: url(../assets/dream-art/section_bg.svg) no-repeat;
}

.framecol1 {
  width: 80%;
  position: absolute;
}
.framecol {
  position: relative;
  // height: 100%;
  top: 25%;
}
::v-deep .v-btn:not(.v-btn--round).v-size--default {
  text-transform: none;
  height: auto;
}
.frametitle {
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 120%;
}
.desc {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
}
.dreamleft {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
}
.join {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 120%;
}
.homebg {
  background: url(../assets/dream-art/cloudbase.png);
  background-repeat: no-repeat;
  background-size: cover;
  // background-size: 100% ;
  position: relative;
  width: 100%;
  background-position: center;
  // height: 392px;
}
.roadmap {
  background: url(../assets/dream-art/montage.png);
  background-repeat: no-repeat;
  background-size: 100%;

  position: relative;
  width: 100%;
  background-position: top;
  min-height: 760px;
  // background: linear-gradient(180deg, rgba(11, 11, 9, 0) 0%, rgba(11, 11, 9, 0.92) 44.79%, #0B0B09 70.31%, #0B0B09 100%);
}
.shade {
  // background: url(../assets/dream-art/shade.svg);

  background: linear-gradient(
    180deg,
    rgba(11, 11, 9, 0) 0%,
    rgba(11, 11, 9, 0.92) 44.79%,
    #0b0b09 70.31%,
    #0b0b09 100%
  );
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  width: 100%;
  background-position: center;
  min-height: 760px;
}
.roadmap2 {
  position: relative;
  min-height: 760px;
  background: linear-gradient(
    180deg,
    rgba(11, 11, 9, 0) 0%,
    rgba(11, 11, 9, 0.92) 44.79%,
    #0b0b09 70.31%,
    #0b0b09 100%
  );
}
.roadmap1 {
  position: absolute;
  top: 50%;

  background: linear-gradient(
    180deg,
    rgba(11, 11, 9, 0) 0%,
    rgba(11, 11, 9, 0.92) 44.79%,
    #0b0b09 70.31%,
    #0b0b09 100%
  );
}
.play {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  text-align: right;
  text-decoration-line: underline;
}
.roadtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  /* identical to box height, or 117% */

  text-align: center;
}
.roaddesc {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  /* or 31px */
  // left: 10%;
  // right: 10%;
  max-width: 60%;
  text-align: center;
}
.cardbg {
  background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.16) 3.01%, rgba(255, 255, 255, 0) 103.3%);
  backdrop-filter: blur(21px);
  box-sizing: border-box;

  border: 2px;
  border-color: #ffffff;
  // position: absolute;
}
.carddesc {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #ffffff;
}
.cardtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */

  text-transform: uppercase;

  color: rgba(255, 255, 255, 0.4) !important;
}
::v-deep .v-slide-group__content {
  white-space: unset;
}
::v-deep .myCarousel .v-window__next {
  position: absolute;
  bottom: 20% !important;
  right: 0 !important;
  top: 80% !important;
  background: none;
  z-index: 10 !important;
}
::v-deep .myCarousel .v-window__prev {
  bottom: 20 !important;
  right: 13% !important;
  left: 87% !important;
  top: 80% !important;
  position: absolute;
  background: none;
}
.cardclr {
  background-color: #0b0b09;
}
.border-gradient {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
}
.border-gradient-purple {
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
.star3class {
  position: absolute;
  right: 0%;
  top: 0%;
}
.conic {
  position: relative;
  z-index: 0;
  // width: 400px;
  // height: 300px;
  // margin: 20px;
  border-radius: 0px;
  overflow: hidden;
  padding: 1rem;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #1a232a;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: conic-gradient(transparent, rgba(255, 255, 255, 1), transparent 30%);
    animation: rotate 4s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 3px;
    top: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    background: #000;
    border-radius: 0px;
  }
}

/* For Tablet View */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  ::v-deep .myCarousel .v-window__next {
    position: absolute;
    bottom: 20% !important;
    right: 0 !important;
    top: 80% !important;
    background: none;
    z-index: 10 !important;
  }
  ::v-deep .myCarousel .v-window__prev {
    bottom: 20% !important;
    right: 25% !important;
    left: 75% !important;
    top: 80% !important;
    position: absolute;
    background: none;
  }
  .claimbtncenter {
    display: flex !important;
    justify-items: center !important;
    justify-content: center;
  }
  .roaddesc {
    max-width: 90%;
    text-align: center;
  }
}
@media screen and (max-width: 600px) {
  .roaddesc {
    max-width: 90%;
    text-align: center;
  }
  .claimbtncenter {
    display: flex !important;
    justify-items: center !important;
    justify-content: center;
  }
  .bgimage {
    width: 100% !important;

    position: absolute;
    transform: rotate(-358deg);
  }
  .formobile {
    height: 30px !important;
    background-size: cover;
  }
  .ismobile {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    text-align: center !important;
    justify-items: center !important;
  }
  .play {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 31px;
    text-align: right;
    text-decoration-line: underline;
  }
  .playmobile {
    padding-top: 24px !important;
  }
  .flexmob {
    display: flex;
    flex-direction: column;
  }
  .join {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    justify-self: center;
    align-items: center;
    justify-items: center;
  }
  .collectionrows {
    margin-top: 0px !important;
    position: relative;
  }
  .startright {
    position: absolute;
    right: 0%;
  }
  .startleft {
    position: absolute;
    left: 0%;
  }
  //   .img2 {
  //   position: absolute;
  //   right: 0% !important;
  // }
  .roadmap {
    background: url(../assets/dream-art/montage.png);
    background-repeat: no-repeat;
    background-size: 100%;

    position: relative;
    width: 100%;
    background-position: top;
    min-height: 200px;
    // background: linear-gradient(180deg, rgba(11, 11, 9, 0) 0%, rgba(11, 11, 9, 0.92) 44.79%, #0B0B09 70.31%, #0B0B09 100%);
  }
  .shade {
    // background: url(../assets/dream-art/shade.svg);

    background: linear-gradient(
      180deg,
      rgba(11, 11, 9, 0) 0%,
      rgba(11, 11, 9, 0.92) 44.79%,
      #0b0b09 70.31%,
      #0b0b09 100%
    );
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;
    width: 100%;
    background-position: center;
    min-height: 200px;
  }
  .roadmap1 {
    position: relative;
    padding-top: 100px;

    background: linear-gradient(
      180deg,
      rgba(11, 11, 9, 0) 0%,
      rgba(11, 11, 9, 0.92) 44.79%,
      #0b0b09 70.31%,
      #0b0b09 100%
    );
  }
  ::v-deep .myCarousel .v-window__next {
    position: absolute;
    bottom: 20% !important;
    right: 0 !important;
    top: 80% !important;
    background: none;
    z-index: 10 !important;
  }
  ::v-deep .myCarousel .v-window__prev {
    bottom: 20% !important;
    left: 0% !important;
    top: 80% !important;
    position: absolute;
    background: none;
  }
}
.collect {
  width: auto;
  // height: 650px;
  margin: auto;
  background-image: url(../assets/dream-art/section_bg.svg);
  background-repeat: no-repeat;
  background-position-x: left;
}

.star {
  animation: glitter 4.5s linear 0s infinite normal;
  -webkit-animation: glitter 4.5s linear 0s infinite normal;
  -moz-animation: glitter 4.5s linear 0s infinite normal;
  -ms-animation: glitter 4.5s linear 0s infinite normal;
  -o-animation: glitter 4.5s linear 0s infinite normal;
}
@-webkit-keyframes glitter {
  0% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
  25% {
    -webkit-transform: scale(0.5);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
  75% {
    -webkit-transform: scale(0.5);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
}
@-moz-keyframes glitter {
  0% {
    -moz-transform: scale(1);
    opacity: 1;
  }
  25% {
    -moz-transform: scale(0.5);
    opacity: 0;
  }
  50% {
    -moz-transform: scale(1);
    opacity: 1;
  }
  75% {
    -moz-transform: scale(0.5);
    opacity: 0;
  }
  100% {
    -moz-transform: scale(1);
    opacity: 1;
  }
}
// svg {
//   width: 100%;
//   height: auto;
// }
::v-deep .v-dialog {
  box-shadow: none !important;
}
.startright {
  position: absolute;
  left: 0%;
}
.startleft {
  position: absolute;
  right: 0%;
}
.leftbtnbg {
  background: rgba(255, 255, 255, 0.3) !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 17px !important;
  padding-bottom: 17px !important;
}
.rightbtnbg {
  background: #ffffff !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding-top: 17px !important;
  padding-bottom: 17px !important;
}
.collectionrows {
  margin-top: 100px;
  position: relative;
}
.block {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.text {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  /* identical to box height */

  color: rgba(255, 255, 255, 0.6);
  text-align: center;
}

.digit {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 56px;

  color: #ffffff;
}
.vertline {
  width: 1px !important;
  height: 44px !important;
  color: rgba(255, 255, 255, 0.4) !important;
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
}
</style>
