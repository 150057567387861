<template>
  <div class="mb-0">
    <v-card color="#312932" elevation="0" :class="!isMobile ? 'container-fluid mt-10 pb-0 mb-0' : 'px-4 mt-10'">
      <v-row no-gutters>
        <v-col class="text-start" lg="9" md="8" cols="12">
          <v-img :src="require('@/assets/img/subscribe_bg.svg')" :class="!isMobile ? 'pt-100 px-16' : 'py-10 px-4'">
            <v-img :src="require('@/assets/img/double_quote.png')" width="38px" height="26px" class="mb-8"></v-img>
            <p class="sub-des w-50">
              The first step in preventing violence against women is to recognize that it is not just an individual
              problem, but a societal one.
            </p>
            <p class="quoteBy pt-5">- Jane Fonda</p>
          </v-img>
        </v-col>
        <v-col v-if="!isMobile" class="text-end text-sm-center videoCol"
          ><video
            playsinline
            autoplay
            muted
            loop
            :width="!isMobile ? '375.8px' : '320px'"
            src="https://da-portal.s3.amazonaws.com/swapna/20230316104839"
          ></video
        ></v-col>
      </v-row>

      <v-form ref="form1" v-model="valid1" lazy-validation :class="!isMobile ? 'mt-200' : 'my-10'">
        <v-divider class="my-10" color="#231526"></v-divider>
        <v-row class="px-0 txtStyle" no-gutters>
          <v-col cols="4" lg="1" md="4" class="text-start mt-3">
            <span @click="scrollToAboutSection" class="cursor">About us</span>
          </v-col>
          <v-col cols="4" lg="1" md="4" class="text-lg-start text-md-start text-center mt-3"
            ><span class="cursor" @click="showHelplineNo">Helpline </span>
          </v-col>

          <v-col cols="4" lg="3" md="4" class="text-lg-start text-md-start text-end mt-3"
            ><span @click="openToContactUsDialog" class="cursor"> Contact us</span></v-col
          >
          <v-spacer class="d-lg-block d-md-none d-none"></v-spacer>
          <v-col lg="3" md="3" cols="12" class="justify-end mt-lg-0 mt-md-4 mt-8 ml-0 d-lg-flex d-md-flex d-flex">
            <v-text-field
              label="email address"
              v-model="emailId1"
              single-line
              prepend-inner-icon="mdi-email"
              :rules="[rules.required, rules.email]"
              solo
              class="elevation-0 pr-lg-3 pr-md-3 pr-2"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn
              id="subcribebtn"
              large
              :loading="loading1"
              :disabled="loading1"
              @click="subscribeMail"
              class="white--text text-none elevation-0 py-6 px-8"
              >Subscribe for daily updates</v-btn
            ></v-col
          >
        </v-row>
      </v-form>
      <v-divider class="mt-3" color="#231526"></v-divider>
    </v-card>
    <v-dialog transition="dialog-bottom-transition " max-width="600" v-model="dialog">
      <v-card>
        <v-toolbar class="elevation-0 fontweight700 px-4">plugin subscription</v-toolbar>
        <v-divider class="my-0 pa-0"></v-divider>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <div class="text-h2 pa-5">
              <v-text-field
                v-model="emailId"
                label="email address"
                placeholder="email address"
                class="font-weight-black"
                :rules="[rules.required, rules.email]"
              ></v-text-field>
              <v-text-field
                v-model="siteUrl"
                label="site url"
                placeholder="site url"
                :rules="[rules.required]"
                class="font-weight-black"
              ></v-text-field>
              <v-text-field
                v-if="isSent"
                v-model="otp"
                label="one time password"
                placeholder="one time password"
                :rules="[rules.required]"
                class="font-weight-black"
              ></v-text-field>
            </div>
            <v-card-actions class="justify-end py-5">
              <v-spacer></v-spacer>
              <v-btn large color="#EBEBEB" class="text-capitalize btnBorder1 black--text" depressed @click="clear">
                Cancel
              </v-btn>
              <v-btn
                large
                v-if="!isSent"
                color="#72307F"
                class="white--text btnSuccess elevation-0"
                :disabled="loading"
                @click="subscribe"
                :loading="loading"
              >
                Submit
              </v-btn>
              <v-btn
                large
                v-if="isSent"
                color="#72307F"
                class="white--text"
                :disabled="loading"
                @click="verify"
                :loading="loading"
              >
                Verify
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- <v-divider class="mt-lg-0 mt-md-0 mt-16"></v-divider> -->

    <v-alert v-if="msg" :type="type">
      {{ msg }}
    </v-alert>
  </div>
</template>
<script>
import api from "@/services/api"

export default {
  mounted() {
    this.onResize()
    window.addEventListener("resize", this.onResize, { passive: true })
  },
  beforeDestroy() {
    if (typeof window === "undefined") return

    window.removeEventListener("resize", this.onResize, { passive: true })
  },
  data: () => ({
    isMobile: false,
    isSent: false,
    loading1: false,
    loading: false,
    emailId1: "",
    emailId: "",
    siteUrl: "",
    otp: "",
    dialog: false,
    valid1: false,
    valid: false,

    msg: "",
    type: "success",
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || "Invalid e-mail."
      },
    },
  }),
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 961
    },
    scrollToAboutSection() {
      this.dialog = false
      this.$root.$emit("ScrollTo", true)
    },
    showHelplineNo() {
      this.$root.$emit("ShowHelplineNo", true)
    },
    scrollToContactusSection() {
      this.active = 4
      this.dialog = false
      this.$root.$emit("ScrollToContactus", true)
    },
    subscribeMail() {
      let valid = this.$refs.form1.validate()
      if (valid) {
        this.loading1 = true
        var input = { email: this.emailId1 }
        api
          .subscribeMail(input)
          .then((resp) => {
            if (resp.data.status == 200) {
              this.loading1 = false
              this.msg = "Successfully Subscribed"
              setTimeout(() => {
                this.emailId1 = ""

                this.msg = ""
                this.$refs.form1.reset()
                this.$refs.form1.resetValidation()
              }, 1000)
            }
          })
          .catch(() => {
            this.loading1 = false
            this.type = "error"
            this.msg = "Already Subscribed"
            this.dialog = false
            setTimeout(() => {
              this.msg = ""
              this.type = "success"
            }, 3000)
          })
      }
    },
    subscribe() {
      let valid = this.$refs.form.validate()
      if (valid) {
        this.loading = true
        var input = { email: this.emailId, siteUrl: this.siteUrl }
        api
          .subscribePlugin(input)
          .then((resp) => {
            if (resp.data.status == 200) {
              this.loading = false
              this.isSent = true
              this.msg = "Successfully Submitted.Please Verify your mail to get the access key"
              setTimeout(() => {
                this.msg = ""
                this.otp = ""
              }, 3000)
            } else {
              this.loading = false
              this.isSent = false
              this.type = "error"
              this.msg = "Already registered.Please use different account or contact admin."
              setTimeout(() => {
                this.$refs.form.reset()
                this.$refs.form.resetValidation()
                this.type = "success"
                this.msg = ""
                this.otp = ""
              }, 3000)
            }
          })
          .catch((e) => {
            this.isSent = false
            this.type = "error"
            this.msg = e.response
            setTimeout(() => {
              this.msg = ""
              this.type = "success"
              this.loading = false
            }, 3000)
          })
      }
    },
    verify() {
      this.loading = true
      let valid = this.$refs.form.validate()
      if (valid) {
        var input = { email: this.emailId, code: this.otp }
        api
          .verifyToken(input)
          .then((resp) => {
            if (resp.data.status == 200) {
              this.dialog = false
              this.isSent = false
              this.msg = "Successfully Verified"
              setTimeout(() => {
                this.clear()
                this.msg = ""

                this.loading = false
              }, 3000)
            } else {
              this.type = "error"
              this.msg = "Invalid code"
              setTimeout(() => {
                this.type = "success"
                this.msg = ""
                this.loading = false
              }, 6000)
            }
          })
          .catch((e) => {
            this.isSent = false
            this.type = "error"
            this.msg = e.response
            this.dialog = false
            setTimeout(() => {
              this.msg = ""
              this.type = "success"
              this.loading = false
            }, 3000)
          })
      }
    },
    clear() {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.isSent = false
      this.emailId = ""
      this.siteUrl = ""
      this.otp = ""
      this.dialog = false
    },
    openToContactUsDialog() {
      this.$root.$emit("emSubContactUs", true)
    },
  },
}
</script>
<style lang="scss" scoped>
.px-100 {
  padding-left: 100px;
  padding-right: 100px;
}
.pt-100 {
  padding-top: 100px;
}
.mt-100 {
  margin-top: 100px;
}
.mt-200 {
  margin-top: 200px;
}
.container-fluid {
  padding: 0 200px;
}
.subscribeBox {
  background: #ffffff !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04) !important;
  border-radius: 2px !important;
}
#subcribebtn {
  padding: 12px 24px;
  gap: 8px;

  background: #72307f;
  border-radius: 2px;
}
.txtStyle {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 18px !important;

  color: white !important;
}
.sub-des {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;
  width: 80%;
  color: #ffffff;
}
.quoteBy {
  font-weight: 300;
  letter-spacing: 0.5px;
  font-size: 16px !important;
  color: #ffffff !important;
}
::v-deep .v-btn__content {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
::v-deep.v-sheet.v-card {
  border-radius: 0px;
}

@media only screen and (min-width: 1440px) and (max-width: 1919px) {
  .container-fluid {
    padding: 0 150px;
  }
  video {
    width: 100%;
  }
}
@media only screen and (min-width: 1023px) and (max-width: 1241px) {
  .container-fluid {
    padding: 0 150px;
  }
  video {
    margin-top: 50px;
    width: 650px !important;
  }
  .col-lg-9 {
    max-width: 100%;
  }
}
@media only screen and (min-width: 1242px) and (max-width: 1439px) {
  .container-fluid {
    padding: 0 150px;
  }
  video {
    margin-top: 50px;
    width: 180px !important;
  }
  .text-sm-center {
    text-align: center;
  }
}
</style>
