<template>
  <div class="container-fluid loginbg" style="overflow: hidden">
    <div id="cardBackground">
      <div class="row cardBackgroundRow">
        <div class="col-lg-4 col-1"></div>
        <div class="col-lg-4 col-10 mt-16 mb-16 log-box">
          <div class="my-3 mx-5">
            <div class="p-4 p-sm-5 text-left">
              <!-- <img src="../assets/Pattern.png" class="patternImg" /> -->
              <v-btn text class="back" color="#FFFFFF" style="padding-left: 0px !important" @click="goBack()">
                <v-icon left size="20"> mdi-chevron-left </v-icon>
                BACK
              </v-btn>
              <div class="justify-center pb-8 pt-11">
                <v-img :src="require('@/assets/dream-art/swapnalogo.png')"></v-img>
              </div>
              <p v-if="!isForgot" class="mt-5 mb-2 mainLogin text-center">Collect your dream artwork</p>
              <p v-else class="mt-5 mb-2 mainLogin text-center">
                Complete the following verification to confirm your identity.
              </p>
              <v-form ref="form" v-model="valid" lazy-validation class="mt-8" v-if="!isForgot">
                <v-text-field
                  v-model="name"
                  :rules="rules('Name', { required: true })"
                  label="Your Name"
                  v-on:keyup.enter="validate"
                  validate-on-blur
                  class="textcolor"
                  solo
                >
                </v-text-field>
                <v-text-field
                  v-model="emailId"
                  :rules="rules('EmailId', { required: true, email: true })"
                  label="Your Email"
                  validate-on-blur
                  v-on:keyup.enter="validate"
                  class="textcolor"
                  solo
                >
                </v-text-field>
                <v-checkbox
                  v-model="accept"
                  dark
                  :rules="[(v) => !!v || 'You must agree to continue!']"
                  label="Do you agree?"
                  required
                  hide-details
                  color="#CCCCCC"
                >
                  <template v-slot:label>
                    <div class="d-flex align-self-center">
                      <div>
                        By checking this your accepting to out terms and Privacy Policies
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <!-- <p class="cyan--text"> -->
                            <router-link to="/terms-privacy">
                              <a class="text-decoration-underline cyan--text" @click.stop v-on="on"
                                >terms and Privacy Policies
                              </a>
                            </router-link>
                            <!-- </p> -->
                          </template>
                          Read our terms and Privacy Policies
                        </v-tooltip>
                      </div>
                    </div>
                  </template>
                </v-checkbox>
                <div v-if="authStatus" class="text-center mt-3 error-text">
                  {{ authStatusMsg }}
                </div>
              </v-form>
              <v-form ref="formCode" v-else v-model="valid" lazy-validation class="mt-8">
                <v-text-field
                  class="textcolor"
                  solo
                  v-model="code"
                  :type="show2 ? 'text' : 'password'"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show2 = !show2"
                  :rules="[(v) => !!v || 'Code is required']"
                  required
                  :hint="hint"
                  persistent-hint
                  validate-on-blur
                  v-on:keyup.enter="validate"
                  label="Enter Code"
                >
                  Enter Code
                </v-text-field>
              </v-form>
              <div class="mt-11 loginBtnCol d-flex justify-center justify-space-around">
                <v-btn
                  tile
                  color="buttonBackground"
                  @click="loginAndVerify()"
                  class="buttonText--text text-none txtStyle btn-block elevation-0"
                  >{{ btntext }}
                  <v-avatar><v-img height="16" contain :src="require('@/assets/arrow.png')"></v-img></v-avatar>
                </v-btn>
              </div>
              <div v-if="!isForgot" class="text-center privacy--text textstyle pt-6">
                <p>We respect your privacy and are committed</p>
                <p>to protecting your personal data.</p>
              </div>
              <div v-if="authStatus" class="text-center mt-3 error-text">
                {{ authStatusMsg }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-1"></div>
      </div>
    </div>
  </div>
</template>
<script>
import validations from "@/utils/validation"
import router from "../router"
import api from "@/services/api"
export default {
  created() {
    this.authStatus = ""
    this.authStatusMsg = ""
    this.isForgot = false
  },
  data: () => ({
    btntext: "Claim Now",
    authStatus: "",
    authStatusMsg: "",
    show1: false,
    valid: false,
    emailId: "",
    name: "",
    accept: false,
    code: "",
    hint: "Enter the 8-digit code sent to ",
    show2: false,
    isForgot: false,
  }),
  methods: {
    rules(label, rules) {
      return validations(label, rules)
    },
    goBack() {
      this.$router.go(-1)
    },
    validate() {
      let valid = this.$refs.form.validate()
      if (valid) {
        if (this.isForgot == false) {
          this.login()
        } else {
          this.verify({
            email: this.emailId,
            code: this.code,
          })
        }
      }
    },
    async loginAndVerify() {
      if (this.btntext == "Claim Now") {
        this.login()
      } else {
        this.verify()
      }
    },

    async login() {
      let valid = this.$refs.form.validate()
      if (valid) {
        let data = { email: this.emailId, name: this.name }
        let result = await api.login(data)

        if (result.status == 200) {
          this.isForgot = true
          this.status = "8 digit code is sent to " + data.emailId
          this.authStatus = ""
          this.authStatusMsg = ""
          this.hint = "Enter the 8-digit code sent to " + data.emailId
          this.btntext = "Verify and Claim"
          setTimeout(() => {
            this.status = ""
          }, 2000)
        } else {
          this.authStatus = "error"
          this.authStatusMsg = result.data
        }
      }
    },
    async verify() {
      let valid = this.$refs.formCode.validate()
      if (valid) {
        let data = {
          email: this.emailId,
          otp: this.code,
        }
        let result = await api.verifyOtp(data)
        if (result.data.status == 200) {
          this.status = "Login Successful "
          this.authStatus = ""
          this.authStatusMsg = ""
          this.isForgot = false
          sessionStorage.setItem("id", result.data.user.id)
          sessionStorage.setItem("name", result.data.user.name)
          sessionStorage.setItem("emailId", result.data.user.email)
          sessionStorage.setItem("isLogged", true)
          this.$root.$emit("isLogged", true)
          if (result.data.dreamCount >= 1) {
            sessionStorage.setItem("isClaimed", true)
            router.push("/my-account")
          } else {
            sessionStorage.setItem("isClaimed", false)
            router.push("/reveal-dream")
          }
          setTimeout(() => {
            this.status = ""
          }, 2000)
        } else {
          this.authStatus = "error"
          this.authStatusMsg = result.data.msg
        }
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0)
  },
}
</script>

<style scoped lang="scss">
.error-text {
  color: red;
}
.log-box {
  border-radius: 8px;
  opacity: 1;
}
small {
  font-size: 16px;
}
.signUp {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.SignUpLink {
  padding-left: 4px;
}
.ForgotPasswordLink {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  flex: none;
  order: 1;
  flex-grow: 0;
}
.patternImg {
  margin-top: 10px;
}
.v-avatar {
  height: 24px !important;
  width: 24px !important;
  justify-content: right;
}
.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
.error-text {
  color: red;
}
@media (min-width: 239px) and (max-width: 320px) {
  /deep/.v-btn:not(.v-btn--round).v-size--x-large {
    padding: 0px;
    font-size: 12px;
  }

  /deep/.v-avatar {
    height: 15px !important;
    width: 15px !important;
    justify-content: left;
  }
  .rememberMeChkCol {
    height: 0px;
  }
}
#passwordLBL,
#emailLBL {
  font-weight: bolder;
}
.loginbg {
  background: rgb(114, 48, 127);
  background: linear-gradient(153deg, rgba(114, 48, 127, 0.938813025210084) 0%, rgba(0, 0, 0, 1) 39%);
  min-height: 100vh;
}

#cardBackground {
  // background-image: url(../assets/login.svg) no-repeat ;
  width: 100%;
}

@media (min-width: 280px) and (max-width: 319px) {
  .loginBtnCol {
    width: 100%;
  }
  /deep/.v-btn:not(.v-btn--round).v-size--x-large {
    padding: 0px;
    font-size: 14px;
  }
  .cardBackgroundRow {
    margin: 0px;
  }
}
.mainLogin {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
}
.v-text-field--outlined,
.v-text-field--solo {
  border-radius: 0px !important;
}
::v-deep .v-text-field--solo > .v-input__control > .v-input__slot {
  background: #3d3d3b !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
}
::v-deep .theme--light.v-label {
  color: #ffffff !important;
}

::v-deep .v-text-field {
  input {
    color: #ffffff !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}
.txtStyle {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 22px !important;

  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
::v-deep .v-btn {
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
::v-deep .v-btn:not(.v-btn--round).v-size--default {
  text-transform: none;
  height: auto;
}
.btn-block {
  width: 100%;
}
.textstyle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
}
.vertline {
  width: 12px;
  height: 0px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  transform: rotate(90deg);
  flex: none;
  order: 1;
  flex-grow: 0;
}
.v-btn:before {
  opacity: 0 !important;
}

.v-ripple__container {
  opacity: 0 !important;
}
.back {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 23px !important;
  /* identical to box height */

  display: flex !important;
  align-items: center !important;

  color: #ffffff !important;
}
.v-application p {
  margin-bottom: 0px !important;
}
::v-deep .theme--dark.v-icon {
  background: #3d3d3d !important;
  color: #cccccc !important;
}
</style>
