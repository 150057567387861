<template>
  <div style="position: relative" class="margin100">
    <v-img
      :src="require('@/assets/images/home/strip.png')"
      cover
      :class="!isMobile ? ' event_strip_lg' : 'event_strip_sm'"
    >
    </v-img>
    <v-img
      :src="require('@/assets/images/home/dreamverse_bg.png')"
      aspect-ratio="2"
      :class="!isMobile ? (!isMobileMd ? 'align-center container-left' : 'px-10 align-center ') : 'px-4 align-center '"
      cover
    >
      <v-row class="content-top px-2">
        <v-col class="white--text text-start" lg="3" md="4" cols="12"
          ><h1 class="event-title">
            swapna<br />
            Dreamverse.
          </h1>
          <p class="event-description my-5">
            Celebration of women's dreams, unique collection of digital art that represents dreams of women around the
            world.
          </p>
          <v-row no-gutters v-if="issocialday" class="pt-10">
            <v-col lg="4" md="4" cols="12" class="border-r-1">
              <p class="digit">30k+</p>
              <p class="text">Collectables</p>
            </v-col>
            <v-col
              lg="4"
              md="4"
              cols="12"
              :class="{ 'px-0': $vuetify.breakpoint.smAndDown, 'px-5': $vuetify.breakpoint.mdAndUp }"
            >
              <p class="digit">{{ dreamverseDays }}</p>
              <p class="text">Days</p>
            </v-col>
            <v-col lg="4" md="4" cols="12">
              <p class="digit">{{ collectedDreamArtCount }}+</p>
              <p class="text">Collected Artwork</p>
            </v-col>
          </v-row>
          <div v-else class="d-flex justify-center justify-space-around pt-10">
            <Countdown date="2023-03-21"></Countdown>
          </div>
          <!-- <h1 class="event-livein_text">LIVE IN:</h1>
          <div class="event-countdown mt-5">{{ days }}d {{ hours }}h {{ minutes }}m {{ seconds }}s</div> -->
          <div class="d-flex">
            <v-btn x-large to="/dream-art" color="#6BCDE4" class="text-none btnBorder1 elevation-0 mt-16 py-8"
              >Claim Your Dream Art
              <v-img :src="require('@/assets/arrow.png')" class="ml-8"></v-img>
            </v-btn>
            <v-img
              v-if="!isMobile && !isMobileMd"
              :src="require('@/assets/curve_arrow.png')"
              class="curve-arrow"
              width="80px"
              height="70px"
              position="right"
            ></v-img>
          </div>
        </v-col>
        <!-- <v-spacer></v-spacer> -->
        <v-col class="text-end" lg="9" md="8" cols="12">
          <v-img
            v-if="!isMobile || isMobileMd"
            :src="require('@/assets/images/home/image_reel.png')"
            aspect-ratio="2"
            contain
            position="right"
            :class="!isMobileMd ? 'artwork_reel_lg' : 'mt-10 '"
          >
            <div :class="!isMobileMd ? 'badge_lg' : 'badge_msm '">
              <img
                src="https://da-portal.s3.amazonaws.com/swapna/20230316110623"
                alt=""
                srcset=""
                width="480px"
                height="480px"
                style="background-size: contain; z-index: 2"
              />
            </div>
          </v-img>
          <v-img
            v-if="isMobile"
            :src="require('@/assets/img/artwork1.png')"
            cover
            position="center"
            class="artwork_reel_sm mt-8"
          >
            <div style="position: absolute; left: -100px; top: -100px; z-index: 2">
              <img
                :src="require('@/assets/img/event_logobadge.svg')"
                alt=""
                srcset=""
                width="340px"
                height="340px"
                style="background-size: contain; z-index: 2"
              />
            </div>
          </v-img>
          <!-- <img
              :src="require('@/assets/img/artwork_reel.svg')"
              alt=""
              srcset=""
              style="background-size: contain; height: 680px; position: relative"
            /> -->

          <!-- <v-img
              :src="require('@/assets/img/artwork_reel.svg')"
              aspect-ratio="2"
              contain
              position="right"
              style="position: relative"
            >
              <v-img
                :src="require('@/assets/img/event_logobadge.svg')"
                contain
                style="postion: absolute; left: 0; top: 0"
              ></v-img>
            </v-img> -->
        </v-col>
      </v-row>
    </v-img>
  </div>
</template>
<script>
import Countdown from "@/components/Countdown.vue"
import api from "@/services/api"

export default {
  components: {
    Countdown,
  },
  data: () => ({
    eventDate: new Date("2023-03-21"),
    date: new Date().toISOString().substr(0, 10),
    countdown: 0,
    isMobile: false,
    isMobileMd: false,
    remainingDreamCount: 30000,
    collectedDreamArtCount: "",
    dreamverseDays: "",
  }),
  beforeDestroy() {
    if (typeof window === "undefined") return

    window.removeEventListener("resize", this.onResize, { passive: true })
  },
  async created() {
    let count = await api.getDreamCollectionCount()
    this.remainingDreamCount = 27800 - parseInt(count.data.data)
    this.collectedDreamArtCount = 30000 - this.remainingDreamCount
    const timeDiff = new Date().getTime() - new Date("2023-03-21").getTime()
    this.dreamverseDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
  },
  computed: {
    issocialday() {
      if (new Date(this.date).getTime() >= new Date(this.eventDate).getTime()) {
        return true
      } else {
        return false
      }
    },
    days() {
      return Math.floor(this.countdown / (24 * 60 * 60 * 1000))
    },
    hours() {
      return Math.floor((this.countdown % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000))
    },
    minutes() {
      return Math.floor((this.countdown % (60 * 60 * 1000)) / (60 * 1000))
    },
    seconds() {
      return Math.floor((this.countdown % (60 * 1000)) / 1000)
    },
  },
  mounted() {
    setInterval(() => {
      const now = new Date()
      const end = new Date(this.eventDate)
      this.countdown = end - now
    }, 1000)
    this.onResize()

    window.addEventListener("resize", this.onResize, { passive: true })
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600
      this.isMobileMd = window.innerWidth >= 600 && window.innerWidth < 960
    },
  },
}
</script>
<style lang="scss" scoped>
.content-top {
  margin-top: 250px;
}
.margin100 {
  margin: 80px 0px;
}
.container-left {
  padding-left: 150px;
}
.event-title {
  font-family: "Space Grotesk" !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 48px !important;
  line-height: 52px;
  /* or 108% */

  color: #f9f9f9;
}
.event-description {
  font-family: "Space Grotesk" !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 26px !important;

  color: rgba(255, 255, 255, 0.6);
}
.event-livein_text {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  /* identical to box height */

  color: rgba(255, 255, 255, 0.6);
}
.event-countdown {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 56px;

  color: #ffffff;
}
::v-deep .v-btn__content {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  /* Text */

  color: #100f10;
}
.event_strip_lg {
  z-index: 2;
  position: absolute;
  top: -90px;
  right: 0;
  left: 0;
}
.event_strip_sm {
  z-index: 2;
  position: absolute;
  top: -10px;
  right: 0;
  left: 0;
}
.artwork_reel_sm {
  height: 600px;
  position: relative;
  z-index: 0;
  overflow: visible;
}
.artwork_reel_lg {
  background-size: contain;
  height: 680px;
  position: relative;
  z-index: 0;
  overflow: visible;
}
.badge_lg {
  position: absolute;
  right: 520px;
  top: -150px;
  z-index: 2;
}
.badge_msm {
  position: absolute;
  left: -10px;
  top: -100px;
  z-index: 2;
}
.badge_sm {
  position: absolute;
  left: -150px;
  top: -150px;
  z-index: 2;
}
.ismobile {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  text-align: center !important;
  justify-items: center !important;
}
.block {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.text {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  /* identical to box height */

  color: rgba(255, 255, 255, 0.6);
}

.digit {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 56px;

  color: #ffffff;
}
.vertline {
  width: 1px !important;
  height: 44px !important;
  color: rgba(255, 255, 255, 0.4) !important;
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
}
@media only screen and (min-width: 767px) and (max-width: 1022px) {
  .event_strip_lg {
    top: -35px;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1919px) {
  .event_strip_lg {
    top: -60px;
  }
}
@media only screen and (min-width: 1023px) and (max-width: 1439px) {
  .event_strip_lg {
    top: -40px;
  }
  .badge_lg {
    display: none;
  }
  .curve-arrow {
    width: 50px !important;
    height: 40px !important;
  }
}
@media only screen and (min-width: 200px) and (max-width: 960px) {
  .content-top {
    margin-top: 50px;
  }
  .margin100 {
    margin: 20px 0px;
  }
}
</style>
