<template>
  <div :class="!isMobile ? 'container-fluid mb-0 px-200 ' : 'container-fluid  px-4 '">
    <div class="text-lg-start text-center py-10 d-flex">
      <h5 class="textColor">
        <v-icon size="17" color="white">mdi-copyright</v-icon>
        {{ new Date().getFullYear() }} Digital Align Inc
      </h5>
      <v-divider></v-divider>
      <a href="https://digitalalign.com" target="_blank"> <v-icon size="25" class="" color="white">mdi-web</v-icon></a>
      <a href="https://twitter.com/swaprogram" target="_blank"
        ><v-icon size="25" class="pl-8" color="white">mdi-twitter</v-icon></a
      >
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({ isMobile: false }),

  beforeDestroy() {
    if (typeof window === "undefined") return

    window.removeEventListener("resize", this.onResize, { passive: true })
  },

  mounted() {
    this.onResize()

    window.addEventListener("resize", this.onResize, { passive: true })
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600
    },
  },
}
</script>
<style lang="scss" scoped>
.container-fluid {
  margin-top: 0px;
  color: white !important;
  background: #312932;
}
.px-200 {
  padding: 0 200px;
}
.textColor {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  color: #ffffff;
}
.py-25 {
  padding: 25px;
}
@media only screen and (min-width: 1440px) and (max-width: 1919px) {
  .container-fluid {
    padding: 0 150px;
  }
  video {
    width: 100%;
  }
}
@media only screen and (min-width: 1023px) and (max-width: 1241px) {
  .container-fluid {
    padding: 0 150px;
  }
}
@media only screen and (min-width: 1242px) and (max-width: 1439px) {
  .container-fluid {
    padding: 0 150px;
  }
}
</style>
