<template>
  <div class="container">
    <div class="container text-center" v-if="isVerified">
      <img :src="require('@/assets/images/shared/logo-black.svg')" />
      <h2 class="mt-5">Congrats! Your Email is Verified.</h2>
      <p class="my-3">You will be notified once the Admin Approves your request.</p>
      <h2>Thank You.</h2>
    </div>
    <div v-if="!isVerified">
      <h3>Sorry Invalid Link</h3>
    </div>
  </div>
</template>
<script>
import api from "@/services/api"

export default {
  created() {
    var address = window.location
    var token = address.toString().split("=")[1]
    this.verify(encodeURI(token))
  },
  computed: {},
  data: () => ({
    isVerified: false,
  }),

  methods: {
    verify(code) {
      api
        .verifyToken(code)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.isVerified = true
          }
        })
        .catch((e) => {
          this.isVerified = false
        })
    },
  },
}
</script>
