import axios from "axios";

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("accessToken");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});


axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    return error.response.data;
  }
);


export default axios
