<template>
  <div :class="showHeatMap ? 'home d-flex flex-column ' : 'home align-center'">
    <div style="background: #6e2e7d; height: 80vh" class="relative" v-if="!showHeatMap">
      <div
        :class="
          !isMobile
            ? !isMobileSm
              ? 'd-flex relative overlay overflow-hidden'
              : 'd-flex flex-column relative overflow-hidden'
            : 'relative text-end overflow-hidden'
        "
      >
        <v-img
          :src="require('@/assets/images/home/blindfold_banner.png')"
          min-height="80vh"
          contain
          position="left"
          style="z-index: 1; position: static"
          v-if="!isMobile && !isMobileSm"
          class="blidnflodedwomen"
        >
          <v-img
            src="https://da-portal.s3.amazonaws.com/swapna/20230316104435"
            height="200px"
            contain
            class="circular_logo"
            v-if="!isMobile && !isMobileSm"
          >
          </v-img>
        </v-img>
        <video
          playsinline
          autoplay
          muted
          loop
          :height="!isMobile ? (!isMobileSm ? '80vh' : '1040px') : '760px'"
          :class="!isMobile ? (!isMobileSm ? 'video_lg relative' : ' relative') : ' relative video_sm'"
        >
          <source :src="require('@/assets/video/dream.mp4')" type="video/webm" />
          Your browser does not support the video tag.
        </video>
        <div
          :class="
            !isMobile ? (!isMobileSm ? 'content-body_lg align-end' : 'content-body_msm ') : 'content-body_sm align-end '
          "
          v-if="!showHeatMap"
        >
          <span class="main-title">SWAPNA</span>
          <p class="sub-title text-end pt-3 mb-9">Stop Women Abuse<br />Program North America</p>

          <v-card
            min-height="100px"
            :width="!isMobile ? (!isMobileSm ? '60%' : '100%') : '100%'"
            class="d-flex align-center reportCard"
          >
            <v-card-text class="">
              <v-row>
                <v-col cols="9" class="text-start d-flex flex-column">
                  <span class="qa-title">Do you want to report abuse?</span>
                  <span class="text-start qa-title1"
                    >click here <v-icon size="17px">mdi-arrow-right-thin </v-icon></span
                  >
                </v-col>
                <v-col class="d-flex align-center">
                  <v-btn
                    fab
                    small
                    class="text-end elevation-0"
                    color="#61CAE2"
                    id="btn-ThumbPrint"
                    @click="popUpDialog = true"
                  >
                    <v-img class="ma-2" :src="require('@/assets/circular_arrow.svg')" width="10px" contain></v-img>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <p class="white--text mt-5 reportDetails">
            <v-btn class="white--text reportDetails text-none elevation-0" color="rgb(255 255 255 / 20%)" readonly>
              <v-icon color="white" size="20">mdi-shield-check-outline</v-icon>
              &nbsp;{{ totalReports }} &nbsp;incidents reported till now
            </v-btn>
          </p>
        </div>
      </div>
      <div class="badge_style" style="">
        <a href="#dreamverse" v-smooth-scroll>
          <v-img :src="require('@/assets/images/home/dream_badge.png')"></v-img
        ></a>
      </div>
    </div>
    <v-img
      :src="require('@/assets/img/Background 2.svg')"
      min-height="790px"
      cover
      style="z-index: 0; align-items: center"
      v-if="showHeatMap"
    >
      <div class="content-body1">
        <p class="sub-title my-10 text-center">Stop Women Abuse Program North America</p>
        <v-row class="justify-center">
          <v-col cols="4"></v-col>
          <v-col lg="3" md="6" sm="6" cols="12" class="mx-lg-16 pt-0">
            <v-card min-height="100px" class="d-flex align-center">
              <v-card-text>
                <v-row>
                  <v-col cols="9" class="text-start d-flex flex-column">
                    <span class="qa-title">do you want to report abuse</span>
                    <span class="text-start qa-title1"
                      >click here <v-icon size="17px">mdi-arrow-right-thin </v-icon></span
                    >
                  </v-col>
                  <v-col>
                    <v-btn
                      fab
                      small
                      class="text-end elevation-0"
                      color="#61CAE2"
                      id="btn-ThumbPrint"
                      @click="popUpDialog = true"
                    >
                      <v-img
                        class="ma-2"
                        :src="require('@/assets/circular_arrow.svg')"
                        width="10px"
                        contain
                      ></v-img> </v-btn
                  ></v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-btn class="white--text reportDetails text-none elevation-0 mt-10" color="rgb(255 255 255 / 20%)">
              <v-icon color="white" size="20">mdi-shield-check-outline</v-icon>
              &nbsp;{{ totalReports }} &nbsp;incidents reported till now</v-btn
            >
          </v-col>
          <v-col cols="4"></v-col>
        </v-row>
      </div>
    </v-img>
    <HeatMap v-if="showHeatMap" class="heatMap" :menuItems="items" ref="heatmap"></HeatMap>

    <v-row v-if="!showHeatMap" no-gutters>
      <v-col lg="4" md="4" cols="12"> </v-col>
      <v-col lg="4" md="4" cols="12" :class="'more-info'">
        <v-btn x-large color="#6BCDE4" class="text-none txtStyle btnBorder1 elevation-0" @click="showHeatMap = true"
          >{{ !isMobile ? "See what’s happening around the world " : "want to see more info?" }}&nbsp;<u>click here</u>
          <v-icon>mdi-arrow-right-thin</v-icon>
        </v-btn>
      </v-col>
      <v-col lg="4" md="4" cols="12"> </v-col>
    </v-row>
    <Event v-if="!showHeatMap" id="dreamverse" />

    <v-row class="my-10" v-if="!showHeatMap">
      <v-spacer></v-spacer>
      <v-col> <v-img :src="require('@/assets/divider.svg')" cover width="700px" height="18px"></v-img></v-col>
      <v-spacer></v-spacer
    ></v-row>
    <div id="about-us" v-if="!showHeatMap"><Aboutus></Aboutus></div>
    <v-row class="my-10" v-if="!showHeatMap">
      <v-spacer></v-spacer>
      <v-col> <v-img :src="require('@/assets/divider.svg')" cover width="700px" height="18px"></v-img></v-col>
      <v-spacer></v-spacer
    ></v-row>
    <!-- <News @loading="setVal" v-if="!showHeatMap"></News> -->
    <div><SubcribeSection></SubcribeSection></div>

    <v-dialog v-model="popUpDialog" max-width="700px" persistent>
      <v-card class="text-start">
        <v-card-title class="popup-title pb-0">Have you experienced</v-card-title>
        <!-- <v-card-subtitle class="popup-subtitle"
          >select the type of abuse that you have experienced in the past or experiencing
          now
        </v-card-subtitle> -->

        <v-card-text>
          <span class="popup-subtitle">Click on below visual buttons</span>
          <v-row no-gutters class="mt-3 align-center">
            <v-col v-for="(item, index) in items" :key="index" class="mb-3">
              <v-card
                height="120px"
                max-width="130px"
                elevation="0"
                :class="index == items.length - 1 ? 'pa-3 text-start mr-0' : 'mr-2 pa-3 text-start'"
                :color="item.color"
                @click=";(selectedItem = item.name), (selectedItemId = item.id)"
              >
                <v-img max-width="32px" max-height="32px" :src="item.imgUrl"></v-img>
                <v-card-text class="pl-0 py-0 mt-3 popup-textstyle pr-9">{{ item.name }}</v-card-text>
              </v-card>
              <v-divider
                class="selectedItemdivider mt-3 mr-2"
                :color="item.color"
                v-show="item.name == selectedItem"
              ></v-divider>
            </v-col>
          </v-row>
          <div class="d-flex align-center">
            <v-checkbox v-model="isGetHelp" color="#72307f"></v-checkbox>
            <div class="d-flex flex-column justify-center">
              <span class="helpTxt"> get help</span>
              <span class="helpSubTxt">let us know if you need help.</span>
            </div>
          </div>
        </v-card-text>

        <v-divider class="mt-5"></v-divider>

        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            depressed
            class="px-lg-10 px-md-8 px-5"
            @click=";(selectedItem = ''), (selectedItemId = 0), (popUpDialog = false), (isGetHelp = false)"
          >
            Cancel
          </v-btn>
          <v-btn
            color="#72307F"
            class="px-lg-12 px-md-8 px-5 white--text btnSuccess elevation-0"
            :disabled="selectedItem == '' || loading"
            @click="isGetHelp ? ((popUpDialog = false), (dialogGetHelp = true)) : submit()"
            :loading="loading"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" persistent max-width="450px">
      <v-card outlined class="pa-4">
        <!-- <img class="mt-7" :src="" /> -->
        <v-card-title class="d-flex flex-column align-center black--text font-weight-bold">
          <v-btn fab class="elevation-0 mb-6 btnBorder" color="#61CAE2">
            <v-icon color="white" size="30">mdi-check-circle-outline</v-icon>
          </v-btn>
          reported successfully
        </v-card-title>
        <v-divider class="my-4"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#72307F"
            class="text-capitalize btnSuccess white--text elevation-0"
            block
            large
            depressed
            @click="dialog = false"
          >
            Done
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogGetHelp" persistent max-width="650px">
      <v-card outlined class="pa-2 card">
        <v-form v-model="valid" ref="form">
          <!-- <img class="mt-7" :src="" /> -->
          <v-card-title class="black--text pb-0" id="getHelp-title"> kindly anwser below questions. </v-card-title>
          <v-card-subtitle id="getHelp-subtitle" class="text-start">
            you are seeing this form because you checked get help box
          </v-card-subtitle>
          <v-card-text class="text-start">
            <v-row no-gutters>
              <v-col lg="6" sm="12">
                <label class="lbl">fullname</label>
                <v-text-field
                  v-model="form.fullName"
                  label="fullname"
                  single-line
                  solo
                  dense
                  class="getHelp-textField mr-2"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col lg="6" md="6" cols="12">
                <label class="lbl">email</label>
                <v-text-field
                  v-model="form.email"
                  label="email"
                  single-line
                  solo
                  dense
                  class="getHelp-textField"
                  :rules="[rules.required, rules.email]"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col lg="6" cols="12">
                <label class="lbl">phone</label>
                <v-text-field
                  v-model="form.phone"
                  label="phone"
                  maxLength="15"
                  single-line
                  solo
                  dense
                  @keypress="onlyNumber($event)"
                  class="getHelp-textField mr-2"
                ></v-text-field>
              </v-col>
              <v-col>
                <!-- <label class="lbl">Purpose</label>
                <v-select
                  :items="purposeList"
                  :rules="[rules.required]"
                  label="Purpose"
                  solo
                  dense
                  v-model="form.purpose"
                  class="getHelp-textField"
                ></v-select> -->
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col lg="6" cols="12" class="pr-2">
                <label class="lbl">address</label>
                <v-textarea solo dense v-model="form.address" label="address" rows="3"></v-textarea>
              </v-col>
              <v-col lg="6" cols="12">
                <label class="lbl">details</label>
                <v-textarea solo dense v-model="form.details" label="details" rows="3"></v-textarea>
              </v-col>
            </v-row>
            <!-- <v-divider class="mb-2"></v-divider>
            <v-row no-gutters>
              <v-col>
                <label class="lbl">Details</label>
                <v-textarea
                  solo
                  dense
                  v-model="form.details"
                  label="Details"
                  rows="3"
                ></v-textarea>
              </v-col>
            </v-row> -->
            <v-divider class="mb-2"> </v-divider>
            <p id="assurence-text" class="text-start mb-0">
              <v-icon size="17" color="#EF8080">mdi-information-outline</v-icon> We assure you that this information
              will not be disclosed.
            </p>
            <v-divider class="mt-2"></v-divider>
          </v-card-text>

          <v-card-actions class="mb-0">
            <v-spacer></v-spacer>
            <v-btn
              color="#EBEBEB"
              class="text-capitalize btnCancel black--text"
              depressed
              @click="clear(), (dialogGetHelp = false)"
            >
              Cancel
            </v-btn>
            <v-btn
              color="#72307F"
              class="text-capitalize btnSuccess white--text elevation-0"
              :disabled="selectedItem == '' || loading"
              :loading="loading"
              @click="submit1()"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-alert v-if="msg" :type="type">
      {{ msg }}
    </v-alert>
  </div>
</template>

<script>
// @ is an alias to /src
import api from "@/services/api"
import HeatMap from "@/components/HeatMapPage.vue"
//import News from "@/components/News.vue";
import Aboutus from "@/components/Aboutus.vue"
import SubcribeSection from "@/components/SubcribeSection.vue"
import Event from "@/components/Event.vue"

//var CryptoJS = require("crypto-js");
export default {
  beforeDestroy() {
    if (typeof window === "undefined") return

    window.removeEventListener("resize", this.onResize, { passive: true })
  },
  mounted() {
    //  this.getMenuItems();
    //Temparary value--new requirement to remove api call
    localStorage.setItem("issueId", 1)
    this.getReports(1)
    this.$root.$on("ScrollTo", (val) => {
      if (val) {
        this.showHeatMap = false
        this.scroll()
      }
    })
    this.$root.$on("assignLatLng", (val) => {
      if (val) {
        this.coords.lat = val.lat
        this.coords.lng = val.lng
        this.isLocationOff = true
      }
    })
    this.onResize()
    window.addEventListener("resize", this.onResize, { passive: true })
  },

  components: {
    HeatMap, //News,
    Aboutus,
    SubcribeSection,
    Event,
  },
  data: () => ({
    isMobile: false,
    isMobileSm: false,
    isTabletMd: false,
    isTabletXXL: false,
    coords: { lat: "", lng: "" },
    isLocationOff: false,
    msg: "",
    type: "success",

    form: {
      fullName: "",
      email: "",
      phone: "",
      website: "",
      purpose: "",
      contactPersonName: "",
      orgName: "",
      address: "",
      details: "",
    },
    purposeList: ["Prevent Women Abuse", "Support Women", "Participate in this Cause", "Other"],
    showHeatMap: false,
    selectedItem: "",
    selectedItemId: 0,
    popUpDialog: false,
    items: [
      {
        id: 1,
        name: "the stare",
        color: "#FDF2D0",
        icon: "",
        imgUrl: "https://da-portal.s3.amazonaws.com/swapna/20230106204726",
      },
      {
        id: 2,
        name: "shoving around",
        color: "#EEE170",
        icon: "",
        imgUrl: "https://da-portal.s3.amazonaws.com/swapna/20230106204825",
      },
      {
        id: 3,
        name: "verbal abuse",
        color: "#F5C242",
        icon: "",
        imgUrl: "https://da-portal.s3.amazonaws.com/swapna/20230106204755",
      },
      {
        id: 4,
        name: "serious injuries",
        color: "#FEB680",
        icon: "",
        imgUrl: "https://da-portal.s3.amazonaws.com/swapna/20230106204857",
      },
      {
        id: 5,
        name: "life threatening",
        color: "#EF8080",
        icon: "",
        imgUrl: "https://da-portal.s3.amazonaws.com/swapna/20230106204934",
      },
    ],
    loading: false,
    totalReports: 0,
    prevWeekReports: 0,
    currWeekReports: 0,
    dialog: false,
    isGetHelp: false,
    dialogGetHelp: false,
    valid: false,
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || "Invalid e-mail."
      },
    },
    isNewsLoaded: false,
    show: true,
  }),
  methods: {
    onResize() {
      this.isMobile = window.innerWidth <= 600
      this.isMobileSm = window.innerWidth > 600 && window.innerWidth <= 960
      this.isTabletMd = window.innerWidth > 960 && window.innerWidth <= 1264
      this.isTabletXXL = window.innerWidth > 1920
    },
    onlyNumber($event) {
      if ($event.charCode === 0 || /^[0-9,]+$/.test(String.fromCharCode($event.charCode))) {
        return true
      } else {
        $event.preventDefault()
      }
    },
    setVal(val) {
      this.isNewsLoaded = val
    },
    getReports(id) {
      api.getReport1(id).then((resp) => {
        this.totalReports = resp.data.total
      })
    },
    scroll() {
      const element = document.getElementById("about-us")
      element.scrollIntoView({ behavior: "smooth" })
    },
    getMenuItems() {
      var issueType = "Abuse"
      api
        .getMenus(issueType)
        .then((resp) => {
          this.items = resp.data.records[0].typeDetailsList
          localStorage.setItem("issueId", resp.data.records[0].id)
          this.getReports(resp.data.records[0].id)
        })
        .catch((e) => {})
    },
    async submit() {
      const permissionStatus = await navigator.permissions.query({
        name: "geolocation",
      })
      const hasPermission = permissionStatus.state
      if (hasPermission != "denied") {
        if (this.isGetHelp) {
          let valid = this.$refs.form.validate()
          this.loading = true
          if (valid) {
            navigator.geolocation.getCurrentPosition(this.addReport)
          }
        } else {
          this.loading = true
          navigator.geolocation.getCurrentPosition(this.addReport)
        }
        this.loading = true
      } else {
        if (this.coords.lat == "" && this.coords.lng == "") {
          this.$root.$emit("ShowEnterAddressDialog", true)
        } else {
          this.$root.$emit("ShowEnterAddressDialog", false)
          this.loading = true
          this.addReport({
            coords: {
              latitude: this.coords.lat,
              longitude: this.coords.lng,
            },
          })
        }
      }
      // if (navigator.geolocation) {
      //   navigator.geolocation.getCurrentPosition(this.addReport);
      // }
    },
    async submit1() {
      let valid = this.$refs.form.validate()
      if (this.isGetHelp) {
        if (valid) {
          const permissionStatus = await navigator.permissions.query({
            name: "geolocation",
          })
          const hasPermission = permissionStatus.state
          if (hasPermission != "denied") {
            this.loading = true
            navigator.geolocation.getCurrentPosition(this.addReport)
          } else {
            if (this.coords.lat == "" && this.coords.lng == "") {
              this.$root.$emit("ShowEnterAddressDialog", true)
            } else {
              this.loading = true
              this.addGetHelp()
              //  this.$root.$emit("ShowEnterAddressDialog", false);
              this.loading = true
              this.addReport({
                coords: {
                  latitude: this.coords.lat,
                  longitude: this.coords.lng,
                },
              })
            }
          }
        }
      }
    },
    addReport(position) {
      var lat = position.coords.latitude
      var lon = position.coords.longitude
      var input = {
        typeDetailsId: this.selectedItemId,
        latitude: lat.toString(),
        longitude: lon.toString(),
      }
      api
        .addReport(input)
        .then((resp) => {
          this.popUpDialog = false
          this.dialogGetHelp = false
          if (resp.status == 200) {
            this.getReports(localStorage.getItem("issueId"))
            this.$refs.heatmap.getTotalReport()
            this.$refs.heatmap.analyzedata(this.selectedItemId)
            // this.msg = "Successfully Reported";
            this.dialog = true
            setTimeout(() => {
              this.selectedItem = ""
              this.selectedItemId = 0
              this.loading = false
              this.msg = ""
            }, 3000)
          }
        })
        .catch((e) => {
          this.dialog = false
          this.loading = false
          this.type = "error"
          this.popUpDialog = false
          this.msg = e.response.statusText
          setTimeout(() => {
            this.msg = ""
            this.type = "success"
          }, 3000)
        })
    },
    addGetHelp() {
      api.addGetHelpData(this.form).then((resp) => {
        this.dialogGetHelp = false
        this.getReports(localStorage.getItem("issueId"))
        this.$refs.heatmap.getTotalReport()
        this.loading = false
        this.form = {
          fullName: "",
          email: "",
          phone: "",
          website: "",
          purpose: "",
          contactPersonName: "",
          orgName: "",
          address: "",
          details: "",
        }
        this.isGetHelp = false
        this.$refs.form.reset()
        this.$refs.form.resetValidation()
      })
    },
    clear() {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
    },
  },
}
</script>
<style lang="scss" scoped>
.home {
  overflow-x: hidden;
}
.content-body_lg {
  // margin-top: 100px !important;
  position: absolute;
  right: 100px;
  width: 40%;
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.content-body_sm {
  // margin-top: 100px !important;
  position: absolute;
  padding: 0px 20px;
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  z-index: 5;
  bottom: 10px;
}
.content-body_msm {
  // margin-top: 100px !important;
  position: absolute;
  padding: 0px 30px 0px 100px;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  z-index: 5;
  align-items: flex-end;
  bottom: 100px;
}
.content-body1 {
  // margin-top: 200px !important;
  align-items: center;
  display: grid;
}
.title-text {
  // font-family: monospace !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 28.1053px !important;
  line-height: 23px;
  /* or 81% */

  color: #ffffff;
}
.sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  text-align: right;
  letter-spacing: 0.5px;
  color: #ffffff;
}
.main-title {
  // font-family: "Roboto Slab" !important;
  color: #ffffff;
  font-weight: 700;
  font-size: 40px;
  line-height: 51px;
  letter-spacing: 0.7px;
}
::v-deep .v-text-field--outlined,
.v-text-field--solo {
  border-radius: 2px;
}
.qa-title {
  //font-family: monospace !important;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: -0.05em;
  color: black !important;
}
.qa-title1 {
  // font-family: monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  /* identical to box height */

  letter-spacing: -0.05em;

  color: #697280;
}
::v-deep .v-list-item__subtitle,
.v-list-item__title {
  text-overflow: initial;
}
.reportDetails {
  //font-family: monospace !important;
}
.badge_style {
  position: absolute;
  bottom: -40px;
  right: 250px;
  z-index: 5;
}
.txtStyle {
  letter-spacing: 1px;
  word-spacing: 3px;
  color: black;
  font-weight: bold !important;
}
.txtUnderline {
  text-decoration-line: underline;
}
.popup-textstyle {
  // font-family: "Roboto Mono" !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 18px !important;
  letter-spacing: -0.05em !important;

  /* Palette/Transparent Black/100 */

  color: #000000 !important;
}
.popup-title {
  //font-family: monospace;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.05em;

  /* Palette/Transparent Black/100 */

  color: #000000;
}
.popup-subtitle {
  // font-family: monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  /* identical to box height */
  margin-top: 1px !important;
  letter-spacing: -0.05em;
  color: #697280;
}
.popup-text {
  // font-family: monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.05em;

  color: #697280;
}
.selectedItemdivider {
  border-width: 1px;
}
#btn-ThumbPrint:hover {
  box-shadow: 0 0 0 15px rgba(97, 202, 226, 0.1) !important;
}

.heatMap {
  // margin-top:-100px;
  // z-index:1;
  //position: absolute;
  margin-top: -200px;
}
.btnBorder {
  border: 2px solid #000000 !important;
  border-radius: 80.7619px !important;
}
.btnSuccess {
  padding: 12px 24px !important;
  gap: 8px !important;
  background: #72307f !important;
  border-radius: 2px !important;
}
.btnCancel {
  padding: 12px 24px !important;
  gap: 8px !important;
  border-radius: 2px !important;
}
.helpTxt {
  //  font-family: monospace;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.03em;
  color: #000000 !important;
}
.helpSubTxt {
  //font-family: monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */

  letter-spacing: -0.05em;

  color: #697280;
}
.chkHelp {
  background: #ffffff;
  border: 1px solid #d0d5dd;
}
#getHelp-title {
  //font-family: "Roboto Mono";
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.05em;

  /* Palette/Transparent Black/100 */

  color: #000000;
}
#getHelp-subtitle {
  //font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  letter-spacing: -0.05em;
  margin-top: 2px;
  color: #697280;
}
.lbl {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 520;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: -0.03em;
  color: #000000;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  gap: 8px;
  background: #ffffff;
  border: 1px solid #ececec !important;
  border-radius: 2px;
  box-shadow: none !important;
}
#assurence-text {
  //font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.05em;

  color: #ef8080;
}
.card {
  background: #ffffff !important;
  box-shadow: 0px 8.07619px 24.2286px rgba(0, 0, 0, 0.05) !important;
  border-radius: 2px !important;
}
::v-deep .v-text-field.v-text-field--solo .v-label {
  font-size: 12px !important;
}
.btnBorder1 {
  border: none !important;
}

::v-deep .v-list-item__content {
  text-align: start !important;
}
.overlay {
  background: #5c2a66;
  // margin-left: 200px;
  margin-right: 180px;
  height: 80vh;
  overflow: hidden;
  justify-content: center;
}
video {
  filter: grayscale(100%);
  opacity: 0.5;
  min-width: 820px;
  object-fit: cover;
  height: 80vh;
  object-position: bottom;
}
.video_sm {
  filter: grayscale(100%);
  opacity: 0.5;
  object-fit: cover;
  min-width: 100%;
  height: 80vh;
  object-position: bottom;
}
.more-info {
  margin: 100px 0px 70px !important;
  display: flex;
  justify-content: center;
}
.more-info_sm {
  margin: 100px 10px !important;
  display: flex;
  justify-content: center;
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}
.video_lg {
  right: 346px;
}
.circular_logo {
  z-index: 1;
  position: absolute;
  left: 110px;
  bottom: 200px;
}
@media only screen and (min-width: 200px) and (max-width: 960px) {
  .v-btn.v-size--x-large {
    font-size: 14px !important;
  }
  .more-info_sm {
    margin: 10px 10px !important;
  }
}
@media only screen and (min-width: 1023px) and (max-width: 1025px) {
  .overlay {
    margin-right: 0px !important;
  }
  .video_lg {
    left: 0;
  }
  .blidnflodedwomen {
    display: none;
  }
  video {
    min-width: 700px;
  }
  .reportCard {
    width: 100% !important;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1240px) {
  .overlay {
    margin-right: 0px !important;
  }
  .video_lg {
    left: -300px;
  }
  .blidnflodedwomen {
    display: none;
  }
  video {
    min-width: 700px;
  }
  .reportCard {
    width: 100% !important;
  }
}
@media only screen and (min-width: 1241px) and (max-width: 1439px) {
  .overlay {
    margin-right: 0px !important;
  }
  .video_lg {
    left: -300px;
  }
  .blidnflodedwomen {
    display: block;
  }
  .circular_logo {
    z-index: 1;
    position: absolute;
    left: 50px;
    bottom: 80px;
  }
  video {
    min-width: 700px;
  }
  .reportCard {
    width: 100% !important;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1450px) {
  .overlay {
    margin-right: 0px !important;
  }
  .video_lg {
    left: 0px;
  }
  .blidnflodedwomen {
    display: none;
  }
  video {
    min-width: 1019px;
  }
  .reportCard {
    width: 100% !important;
  }
  .overlay {
    justify-content: start;
  }
}
@media only screen and (min-width: 1950px) and (max-width: 1960px) {
  .video_lg {
    left: -300px;
  }
}
@media only screen and (min-width: 1961px) and (max-width: 1990px) {
  .video_lg {
    left: -330px;
  }
}
@media only screen and (min-width: 1990px) and (max-width: 2100px) {
  .video_lg {
    left: -400px;
  }
}
@media only screen and (min-width: 2100px) and (max-width: 2225px) {
  .video_lg {
    left: -460px;
  }
}
@media only screen and (min-width: 2225px) and (max-width: 2345px) {
  .video_lg {
    left: -500px;
  }
}
@media only screen and (min-width: 2345px) and (max-width: 2480px) {
  .video_lg {
    left: -640px;
  }
}
@media only screen and (min-width: 2480px) and (max-width: 2526px) {
  .video_lg {
    left: -680px;
  }
}
@media only screen and (min-width: 2526px) and (max-width: 2565px) {
  .video_lg {
    left: -720px;
  }
}
@media only screen and (min-width: 2565px) and (max-width: 2845px) {
  .video_lg {
    left: -1000px;
  }
}
@media only screen and (min-width: 2845px) and (max-width: 2845px) {
  .video_lg {
    left: -880px;
  }
  video {
    min-width: 1019px;
  }
}
@media only screen and (min-width: 2891px) and (max-width: 2970px) {
  .video_lg {
    left: -960px;
  }
  video {
    min-width: 1019px;
  }
}
@media only screen and (min-width: 2971px) and (max-width: 3050px) {
  .video_lg {
    left: -1040px;
  }
  video {
    min-width: 1019px;
  }
}

@media only screen and (min-width: 3840px) {
  .video_lg {
    left: -1240px;
  }
  video {
    min-width: 1019px;
  }
}
</style>
