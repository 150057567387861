<template>
  <div>
    <v-btn color="primary" @click="dialog = true"> Open Dialog </v-btn>
    <br /><br />

    <v-dialog v-model="dialog" max-width="860px" class="elevation-0">
      <iframe
        width="560"
        height="470"
        :src="
          'https://www.youtube.com/embed/xzsYCB4at9Y?rel=0&modestbranding=0&autohide=1&showinfo=0&controls=1&autoplay=' +
          isAutoPlay
        "
        frameborder="0"
        allow=" autoplay;encrypted-media;picture-in-picture;"
        allowfullscreen
      ></iframe>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
    url:
      "https://www.youtube.com/embed/xzsYCB4at9Y?rel=0&modestbranding=0&autohide=1&showinfo=0&controls=1&autoplay=",
  }),
  computed: {
    isAutoPlay() {
      return this.dialog ? 1 : 0;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-dialog {
  box-shadow: none !important;
}
</style>
