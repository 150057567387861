<template>
  <div class="termsbg" v-if="!zoomimage">
    <div class="bg">
      <div class="container text-left">
        <div class="d-flex justify-space-between formobile">
          <v-btn text class="back mt-10" color="#FFFFFF" @click="goBack()">
            <v-icon left size="20"> mdi-chevron-left </v-icon>
            BACK
          </v-btn>
          <v-btn text class="logout mt-10" color="#FFFFFF" @click="logout()">
            <v-icon left size="20"> mdi-logout </v-icon>
            Log Out
          </v-btn>
        </div>
        <v-row justify="center">
          <v-col align-self="start" class="d-flex justify-center align-center pa-0" cols="12">
            <v-avatar class="profile avatar-center-heigth avatar-shadow" size="96">
              <span class="white--text avttext">{{ initials }}</span>
            </v-avatar>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="container mt-16 text-center">
      <p class="white--text name">{{ name }}</p>
      <p class="white--text email">{{ email }}</p>

      <hr class="hrline my-8" />
      <p class="mycol">My Dream Collections</p>
    </div>
    <v-container v-if="collection.length != 0">
      <div class="d-flex flex-wrap justify-center">
        <div v-for="item in collection" :key="item.no">
          <v-card color="cardclr" class="ma-4 pa-3" min-height="300" width="250" @click="toggleFullscreen(item)">
            <v-img class="" :src="item.dreamArtUrl">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="white"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <div class="d-flex justify-space-between mt-3">
              <div class="slidename">Swapna Dreams</div>
              <div class="slideno">{{ item.dreamArtName.split(".")[0] }}</div>
            </div>
            <hr class="hrline my-2" />
            <p class="slidedate text-left mb-3">
              {{ new Date(item.createdOn).toDateString() }}
            </p>
          </v-card>
        </div>
      </div>
      <div class="py-10 text-grey d-flex justify-center">
        <p class="text-bottom">
          <i>Note: Please be aware that each user can only claim one dream artwork at a time.</i>
        </p>
      </div>
    </v-container>
    <v-container class="d-flex flex-wrap justify-center align-center text-white py-10" v-else>
      <h2>No Dream Collection</h2>
    </v-container>
  </div>
  <div v-else>
    <div class="text-center justify-center d-flex align-center flex-column emptyCard">
      <div class="pa-4 borderclass d-flex justify-center">
        <div
          class="d-flex align-center justify-center cardbgreveal"
          width="300"
          height="368"
          style="position: relative"
        >
          <v-img :src="selectedImage.dreamArtUrl" cover></v-img>
        </div>
      </div>

      <div class="mt-9 cardtext white--text">SWAPNA DREAMS - {{ selectedImage.dreamArtName.split(".")[0] }}</div>

      <div class="d-flex mt-12">
        <v-btn
          tile
          color="buttonBackground"
          @click="downloadImage()"
          class="buttonText--text text-none txtStyle btn-block elevation-0 mr-6"
          >Download
        </v-btn>

        <v-btn
          tile
          color="buttonText"
          class="white--text text-none txtStyle btn-block elevation-0"
          @click="closeZoomImage"
          >My Account
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/services/api"
import FileSaver from "file-saver"

export default {
  components: {},

  data: () => ({
    selectedImage: null,
    zoomimage: false,
    dialog: false,
    overlay: false,
    model: null,
    name: sessionStorage.getItem("name"),
    email: sessionStorage.getItem("emailId"),
    collection: [],
  }),
  computed: {
    initials() {
      var names = ""
      if (this.name) {
        names = this.name.split(" ")
      }
      let initial = names[0].substring(0, 1).toUpperCase()
      if (names.length > 1) {
        initial += names[names.length - 1].substring(0, 1).toUpperCase()
      }
      return initial
    },
  },
  async created() {
    let result = await api.getDreamCollection(sessionStorage.getItem("id"))
    this.collection = result.data.data
  },
  methods: {
    goBack() {
      this.$router.push("/dream-art")
      //this.$router.go(-1)
    },
    logout() {
      sessionStorage.clear()
      this.$router.push("/login-or-register")
      this.$root.$emit("isLogged", false)
    },
    toggleFullscreen(elem) {
      this.selectedImage = elem

      this.zoomimage = true
    },
    closeZoomImage() {
      this.zoomimage = false
      this.selectedImage = {}
    },
    async downloadImage() {
      let result = await api.downloadDreamImage(this.selectedImage.dreamArtName)
      if (result) {
        let image = `data:image/png;base64,${result.data.data}`
        FileSaver.saveAs(image, result.data.imageName)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}
.bg {
  background-color: #231526;
  min-height: 150px;
  position: relative;
}
.txtStyle {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 22px !important;
}
::v-deep .v-btn {
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
::v-deep .v-btn:not(.v-btn--round).v-size--default {
  text-transform: none;
  height: auto;
}
.back {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 23px !important;
  padding-left: 0px !important;
  display: flex !important;
  align-items: center !important;
  color: #ffffff !important;
}

.logout {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 23px !important;
  display: flex !important;
  align-items: center !important;
  color: rgba(255, 255, 255, 0.4) !important;
}

.termsbg {
  background-color: #0b0b09;
  min-height: 100vh;
}

.avatar-center-heigth {
  position: absolute;
  top: 65%;
  background: #72307f;
  border: 4px solid #ffffff;
}
.avttext {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
}
.name {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.email {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.hrline {
  opacity: 0.42;
  border: 1px solid #454545;
}
.mycol {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #fcfcfd;
}
.slideclass {
  max-width: 50%;
}
.slidename {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  color: #ffffff;
}
.slideno {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  color: #ffffff;
}
.slidedate {
  font-style: normal;
  font-weight: 400;
  font-size: 7.875px;
  line-height: 13px;
  text-align: center;
  color: #939393;
}

@media screen and (max-width: 600px) {
  .slideclass {
    max-width: 100%;
  }
  .formobile {
    margin-top: 24px;
  }
}
.emptyCard {
  padding: 0px;
  margin-top: 150px;
  margin-bottom: 50px;
}

.borderclass {
  border: 3px solid rgba(255, 255, 255, 0.1);
  width: 300px;
}
.cardtext {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
}
.carddesc {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}
.cardbgreveal {
  width: 268px;
  height: 334px;
}

.text-white {
  color: white;
}

.text-grey {
  color: grey;
}

.text-bottom {
  position: absolute;
  bottom: 0;
  padding-bottom: 20px;
}
</style>
