<template>
  <div>
    <v-app-bar
      app
      fixed
      height="80"
      v-if="!isMobile"
      class="header"
      :class="headerColor == 'black' ? 'header-black' : 'header-white'"
    >
      <v-container>
        <v-row align="center">
          <v-toolbar-title class="mx-4 mt-1">
            <v-img :src="logoImageSrc" @click="move()"></v-img>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn text class="ml-3" :input-value="active == 1" active-class="active-link" @click="move()">Home</v-btn>
          <v-btn text class="ml-3" :input-value="active == 2" active-class="active-link" @click="dreamart()"
            >Dream art
          </v-btn>
          <v-btn
            text
            class="ml-3"
            :input-value="active == 4"
            active-class="active-link"
            @click=";(active = 4), (dialogGetHelp = !dialogGetHelp)"
            >Get help?</v-btn
          >
          <v-btn
            text
            class="ml-3"
            :input-value="active == 5"
            active-class="active-link"
            @click=";(active = 5), (dialogContactUs = !dialogContactUs)"
            >Contact us</v-btn
          >
          <v-divider vertical class="mx-5"></v-divider>

          <span class="contact-text mr-15" v-if="!isLocationOff" @click="dialogGetHelplineNo = !dialogGetHelplineNo"
            ><v-icon color="#72307F" size="15">mdi-phone</v-icon> {{ loading ? "..." : "call us" }} ({{
              state + (state != "" && state != null ? "," : "") + country
            }})</span
          >
          <span
            class="contact-text mr-15 cursor"
            v-if="isLocationOff"
            @click="dialogGetHelplineNo = !dialogGetHelplineNo"
            ><v-icon color="#72307F" size="15">mdi-phone</v-icon> &nbsp;call us
          </span>
          <v-btn v-if="islogged" icon @click="gotoMyAccount">
            <v-avatar class="profile avatar-center-heigth avatar-shadow" size="40">
              <span class="white--text avttext">{{ initials }}</span>
            </v-avatar>
          </v-btn>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-app-bar
      absolute
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
      v-if="isMobile"
      class="header"
      :class="headerColor == 'black' ? 'header-black' : 'header-white'"
    >
      <v-toolbar-title class="mx-2">
        <v-img :src="logoImageSrc" max-height="90" max-width="100" @click="window.location.href = '/'"></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click="dialog = true"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-row>
      <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card class="pa-2">
          <v-toolbar color="white" class="elevation-0">
            <v-img
              :src="require('@/assets/images/shared/logo-black.svg')"
              max-height="90"
              max-width="100"
              @click="move()"
            ></v-img>
            <v-spacer></v-spacer>
            <v-toolbar-items class="justify-end">
              <v-btn icon dark @click="dialog = false">
                <v-icon color="black">mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text
            class="contact-text mt-2 text-end mb-0"
            v-if="!isLocationOff"
            @click="dialogGetHelplineNo = !dialogGetHelplineNo"
          >
            <v-icon color="#72307F" size="15">mdi-phone</v-icon>
            {{ loading ? "..." : helplineNo }} ({{ state + (state != "" && state != null ? "," : "") + country }})
          </v-card-text>
          <v-card-text
            class="contact-text mt-2 text-end mb-0"
            v-if="isLocationOff"
            @click="dialogGetHelplineNo = !dialogGetHelplineNo"
          >
            <v-icon color="#72307F" size="15">mdi-phone</v-icon> &nbsp;helpline no
          </v-card-text>

          <v-list>
            <v-subheader>Menu</v-subheader>
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item
                v-for="(item, i) in items"
                :key="i"
                link
                @click="
                  item.click == 'move'
                    ? move()
                    : item.click == 'dreamart'
                    ? dreamart()
                    : item.click == 'help'
                    ? (dialogGetHelp = !dialogGetHelp)
                    : ((dialog = false), (dialogContactUs = !dialogContactUs))
                "
              >
                <!-- <v-list-item-icon class="mr-5">
                  <v-icon v-text="item.icon" size="20"></v-icon>
                </v-list-item-icon> -->
                <v-list-item-content class="text-start">
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-dialog>
    </v-row>

    <v-dialog v-model="dialog1" persistent max-width="450px">
      <v-card outlined class="pa-4">
        <!-- <img class="mt-7" :src="" /> -->
        <v-card-title class="d-flex flex-column align-center black--text">
          <v-btn fab class="elevation-0 mb-6 btnBorder" color="#61CAE2">
            <v-icon color="white" size="30">mdi-check-circle-outline</v-icon>
          </v-btn>
          {{ dialogText }}
        </v-card-title>
        <v-divider class="my-4"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#72307F"
            class="text-capitalize btnSuccess white--text elevation-0"
            block
            large
            depressed
            @click="dialog1 = false"
          >
            Done
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showHelplineNoDialog" persistent max-width="450px">
      <v-card outlined class="pa-4">
        <!-- <img class="mt-7" :src="" /> -->
        <v-card-title class="d-flex flex-column align-center black--text">
          <v-btn fab class="elevation-0 mb-6 btnBorder" color="#61CAE2">
            <v-icon color="white" size="30">mdi-phone</v-icon>
          </v-btn>
          <div class="d-lg-flex d-md-flex d-block">
            helpline number is &nbsp;<span class="blue--text">{{ helplineNo }}</span>
          </div>
        </v-card-title>
        <v-divider class="my-4"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize black--text elevation-0"
            block
            large
            depressed
            @click="showHelplineNoDialog = false"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogGetHelp" persistent max-width="650px">
      <v-card outlined class="pa-2 card">
        <v-form v-model="valid" ref="form">
          <!-- <img class="mt-7" :src="" /> -->
          <v-card-title class="black--text pb-0" id="getHelp-title"> kindly anwser below questions. </v-card-title>
          <v-card-subtitle id="getHelp-subtitle" class="text-start">
            you are seeing this form because you checked get help box
          </v-card-subtitle>
          <v-card-text class="text-start">
            <v-row no-gutters>
              <v-col lg="6" sm="12">
                <label class="lbl">fullname</label>
                <v-text-field
                  v-model="form.fullName"
                  label="fullname"
                  single-line
                  solo
                  dense
                  class="getHelp-textField mr-2"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col lg="6" md="6" cols="12">
                <label class="lbl">email</label>
                <v-text-field
                  v-model="form.email"
                  label="email"
                  single-line
                  solo
                  dense
                  class="getHelp-textField"
                  :rules="[rules.required, rules.email]"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col lg="6" cols="12">
                <label class="lbl">phone</label>
                <v-text-field
                  v-model="form.phone"
                  label="phone"
                  maxLength="15"
                  single-line
                  solo
                  @keypress="onlyNumber($event)"
                  dense
                  class="getHelp-textField mr-2"
                ></v-text-field>
              </v-col>
              <v-col>
                <!-- <label class="lbl">Purpose</label>
                <v-select
                  :items="purposeList"
                  :rules="[rules.required]"
                  label="Purpose"
                  solo
                  dense
                  v-model="form.purpose"
                  class="getHelp-textField"
                ></v-select> -->
              </v-col>
            </v-row>
            <v-divider class="mb-2"></v-divider>
            <v-row no-gutters>
              <v-col class="pr-2" lg="6" cols="12">
                <label class="lbl">address</label>
                <v-textarea solo dense v-model="form.address" label="address" rows="2"></v-textarea>
              </v-col>
              <v-col lg="6" cols="12">
                <label class="lbl">details</label>
                <v-textarea solo dense v-model="form.details" label="details" rows="2"></v-textarea>
              </v-col>
            </v-row>
            <v-divider class="mb-2"> </v-divider>
            <p id="assurence-text" class="text-start mb-0">
              <v-icon size="17" color="#EF8080">mdi-information-outline</v-icon>
              We assure you that this information will not be disclosed.
            </p>
            <v-divider class="mt-2"></v-divider>
          </v-card-text>

          <v-card-actions class="mb-0">
            <v-spacer></v-spacer>
            <v-btn
              color="#EBEBEB"
              class="text-capitalize btnCancel black--text"
              depressed
              @click="clear(), (dialogGetHelp = false), (active = 1)"
            >
              Cancel
            </v-btn>
            <v-btn
              color="#72307F"
              class="text-capitalize btnSuccess white--text elevation-0"
              :disabled="loading"
              :loading="loading"
              @click="addGetHelp()"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogContactUs" persistent :max-width="!isMobile ? '620px' : '400px'">
      <v-card outlined class="pa-2 card">
        <v-card-title class="black--text pa-3" id="getHelp-title"> kindly anwser below questions. </v-card-title>
        <v-form v-model="valid" ref="form1">
          <v-card-text class="text-start">
            <v-row no-gutters>
              <v-col lg="6" cols="12">
                <label class="lbl">fullname</label>
                <v-text-field
                  v-model="form.fullName"
                  label="fullname"
                  single-line
                  solo
                  dense
                  class="getHelp-textField mr-2"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col lg="6" cols="12">
                <label class="lbl">email</label>
                <v-text-field
                  v-model="form.email"
                  label="email"
                  single-line
                  solo
                  dense
                  :rules="[rules.required, rules.email]"
                ></v-text-field
              ></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col lg="6" cols="12">
                <label class="lbl">phone</label>
                <v-text-field
                  v-model="form.phone"
                  label="phone"
                  single-line
                  maxLength="15"
                  @keypress="onlyNumber($event)"
                  solo
                  dense
                  class="getHelp-textField mr-2"
                ></v-text-field>
              </v-col>
              <v-col lg="6" cols="12"></v-col>
            </v-row>
            <v-divider class="mb-2"></v-divider>
            <v-row no-gutters>
              <v-col lg="6" cols="12">
                <label class="lbl">organization name</label>
                <v-text-field
                  v-model="form.orgName"
                  label="organization name"
                  single-line
                  solo
                  dense
                  class="getHelp-textField mr-2"
                ></v-text-field>
              </v-col>
              <v-col lg="6" cols="12">
                <label class="lbl">contact person name</label>
                <v-text-field
                  v-model="form.contactPersonName"
                  label="contact person name"
                  single-line
                  solo
                  dense
                  class="getHelp-textField"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col lg="6" cols="12">
                <label class="lbl">website</label>
                <v-text-field
                  v-model="form.website"
                  label="website"
                  single-line
                  dense
                  solo
                  class="getHelp-textField mr-2"
                ></v-text-field>
              </v-col>
              <v-col lg="6" cols="12">
                <label class="lbl">purpose</label>
                <v-select
                  :items="purposeList"
                  label="purpose"
                  solo
                  dense
                  v-model="form.purpose"
                  class="getHelp-textField"
                  :rules="[rules.required]"
                ></v-select>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <label class="lbl">address</label>
                <v-textarea
                  solo
                  dense
                  v-model="form.address"
                  label="address"
                  rows="2"
                  :rules="[rules.required]"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-divider class="mb-2"> </v-divider>
            <p id="assurence-text" class="text-start mb-0">
              <v-icon size="17" color="#EF8080">mdi-information-outline</v-icon>
              We assure you that this information will not be disclosed.
            </p>
            <v-divider class="mt-2"></v-divider>
          </v-card-text>

          <v-card-actions class="mb-0">
            <v-spacer></v-spacer>
            <v-btn
              color="#EBEBEB"
              class="text-capitalize btnCancel black--text"
              depressed
              @click="clear(), (dialogContactUs = false), (active = 1), (form = {})"
            >
              Cancel
            </v-btn>
            <v-btn
              color="#72307F"
              class="text-capitalize btnSuccess white--text elevation-0"
              :disabled="loading"
              :loading="loading"
              @click="addContactUs()"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogGetHelplineNo" persistent max-width="500px">
      <v-card outlined class="pa-2">
        <!-- <img class="mt-7" :src="" /> -->
        <v-card-title id="helpline-title"> please enter the address </v-card-title>

        <v-divider class="my-4"></v-divider>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field class="my-0" v-model="helplineAddress" focus @input="getLocationByAddress"></v-text-field>
              <v-card class="my-0 py-0" outlined>
                <v-list flat class="py-0">
                  <v-subheader>Results</v-subheader>
                  <v-list-item-group v-model="selectedAddItem" color="primary">
                    <v-list-item
                      v-for="(item, i) in addressList"
                      :key="i"
                      v-show="addressList.length > 0"
                      @click=";(helplineAddress = item.formatted_address), assignCN_ST([], [item])"
                    >
                      <v-list-item-content>
                        {{ item.formatted_address }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-show="addressList.length == 0">No data available</v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize black--text elevation-0"
            block
            large
            depressed
            @click="dialogGetHelplineNo = false"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import mapApi from "@/services/apiLicense.js"
import api from "@/services/api"

var geocoder = require("google-geocoder")
var geo = geocoder({
  key: mapApi.apiKey,
})
import { Loader } from "@googlemaps/js-api-loader"
const loader = new Loader({
  apiKey: mapApi.apiKey,
})
export default {
  props: ["headerColor"],
  data: () => ({
    islogged: sessionStorage.getItem("isLogged"),
    active: 1,
    isMobile: false,
    dialog: false,
    dialogGetHelplineNo: false,
    showHelplineNoDialog: false,
    items: [
      { title: "Home", icon: "mdi-home", click: "move" },
      {
        title: "Dream art",
        icon: "mdi-gamepad-circle-down",
        click: "dreamart",
      },

      {
        title: "Get help?",
        icon: "mdi-help-circle-outline",
        click: "help",
      },
      {
        title: "Contact us",
        icon: "mdi-phone",
        click: "contact",
      },
    ],
    purposeList: ["Prevent Women Abuse", "Support Women", "Participate in this Cause", "Other"],
    selectedItem: 0,
    lat: null,
    lng: null,
    country: "",
    state: "",
    helplineNo: "+00 555 666 99",
    loading: false,
    form: {
      fullName: "",
      email: "",
      phone: "",
      purpose: "",
      address: "",
      website: "",
      contactPersonName: "",
      orgName: "",
      details: "",
    },
    dialogContactUs: false,
    dialog1: false,
    dialogText: "",
    dialogGetHelp: false,
    valid: false,
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || "Invalid e-mail."
      },
    },
    isLocationOff: true,
    helplineAddress: "",
    addressList: [],
    selectedAddItem: {},
    isShowHelplineClicked: false,
  }),
  beforeDestroy() {
    if (typeof window === "undefined") return
    window.removeEventListener("resize", this.onResize, { passive: true })
  },
  async created() {
    const permissionStatus = await navigator.permissions.query({
      name: "geolocation",
    })
    const hasPermission = permissionStatus.state
    if (hasPermission != "denied" || hasPermission != "prompt") {
      navigator.geolocation.getCurrentPosition(this.assignCoordinates)
    } else {
      this.isLocationOff = true
    }
  },
  mounted() {
    this.onResize()
    window.addEventListener("resize", this.onResize, { passive: true })
    this.$root.$on("ShowEnterAddressDialog", (val) => {
      if (val) {
        this.dialogGetHelplineNo = val
      }
    })
    this.$root.$on("ShowHelplineNo", (val) => {
      if (val == true) {
        if (this.helplineNo != "") {
          this.dialogGetHelplineNo = false
          this.showHelplineNoDialog = true
        } else {
          this.isShowHelplineClicked = true
          this.dialogGetHelplineNo = true
        }
      }
    })
    this.$root.$on("emSubContactUs", (val) => {
      if (val) {
        this.dialogContactUs = val
      }
    })
    this.$root.$on("isLogged", (val) => {
      if (val) {
        this.islogged = sessionStorage.getItem("isLogged")
      } else {
        this.islogged = false
      }
    })
  },
  computed: {
    logoImageSrc() {
      if (this.headerColor == "black") {
        return require("@/assets/images/shared/logo-white.svg")
      } else {
        return require("@/assets/images/shared/logo-black.svg")
      }
    },
    initials() {
      var name = sessionStorage.getItem("name")
      var names = ""
      if (name) {
        names = name.split(" ")
      }
      let initial = names[0].substring(0, 1).toUpperCase()
      if (names.length > 1) {
        initial += names[names.length - 1].substring(0, 1).toUpperCase()
      }
      return initial
      // return "NR";
    },
  },
  methods: {
    onlyNumber($event) {
      if ($event.charCode === 0 || /^[0-9,]+$/.test(String.fromCharCode($event.charCode))) {
        return true
      } else {
        $event.preventDefault()
      }
    },
    onResize() {
      this.isMobile = window.innerWidth <= 960
    },
    getLocationByAddress() {
      this.isLocationOff = false
      geo.find(this.helplineAddress, this.assignAddress)
    },
    assignAddress(err, res) {
      this.addressList = res
    },
    scrollToAboutSection() {
      this.active = 3
      this.dialog = false
      this.$root.$emit("ScrollTo", true)
    },
    scrollToContactusSection() {
      this.active = 4
      this.dialog = false
      this.$root.$emit("ScrollToContactus", true)
    },
    move() {
      this.active = 1
      window.location = "/"

      this.$root.$emit("ScrollTo", false)
    },
    dreamart() {
      this.active = 2
      this.$router.push("/dream-art")
      this.$root.$emit("ScrollTo", false)
    },
    assignCoordinates(position) {
      this.lat = position.coords.latitude
      this.lng = position.coords.longitude

      this.isLocationOff = false

      geo.reverseFind(this.lat, this.lng, this.assignCN_ST)
    },
    assignCN_ST(err, res) {
      this.country = res[0].country.long_name
      this.state = res[0].administrative_area_level_1.long_name
      if (this.isLocationOff) {
        this.$root.$emit("assignLatLng", {
          lat: res[0].location.lat,
          lng: res[0].location.lng,
        })
      }
      this.isLocationOff = false
      this.getHelplineNo()
    },
    getHelplineNo() {
      var input = { country: this.country, state: this.state }
      api
        .getHelplineNoByCN_ST(input)
        .then((resp) => {
          if (resp.data.records != null) {
            this.helplineNo = resp.data.records.helplineNo
          } else {
            this.helplineNo = "888 777 5555"
          }
          this.dialogGetHelplineNo = false

          if (this.isShowHelplineClicked) {
            this.showHelplineNoDialog = true
          }
          this.loading = false
        })
        .catch((e) => {
          this.loading = false
        })
    },
    addGetHelp() {
      let valid = this.$refs.form.validate()
      if (valid) {
        this.loading = true
        api.addGetHelpData(this.form).then((resp) => {
          this.dialogGetHelp = false
          this.dialogText = "thank you! we'll contact you as soon as possible"
          this.dialog1 = true
          this.loading = false

          this.$refs.form.reset()
          this.$refs.form.resetValidation()
          this.$refs.form1.reset()
          this.$refs.form1.resetValidation()
        })
      }
    },
    addContactUs() {
      let valid = this.$refs.form1.validate()
      if (valid) {
        this.loading = true
        api.addContactUsData(this.form).then((resp) => {
          this.dialogContactUs = false
          this.dialogText = "thank you for contacting us"
          this.dialog1 = true
          this.loading = false
          this.$refs.form.reset()
          this.$refs.form.resetValidation()
          this.$refs.form1.reset()
          this.$refs.form1.resetValidation()
        })
      }
    },
    clear() {
      this.dialogContactUs = false
      this.dialogGetHelp = false
    },
    gotoMyAccount() {
      this.$router.push("/my-account")
    },
  },
}
</script>
<style scoped>
.header {
  font-size: 16px;
  z-index: 999 !important;
}
.header-white {
  background: white !important;
}

.header-black {
  background: black !important;
  color: white !important;
}
::v-deep .v-btn:not(.v-btn--round).v-size--default {
  text-transform: none;
}
.contact-text {
  color: #72307f !important;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
}
.active-link {
  text-decoration-line: underline;
  box-shadow: none !important;
  color: #72307f !important;
  background: white;
}
::v-deep .v-btn:before {
  background: white !important;
}
::v-deep .v-dialog__content {
  height: auto !important;
}
/* .btnBorder {
  border: 2px solid #000000 !important;
  border-radius: 80.7619px !important;
}
.btnSuccess {
  padding: 12px 24px !important;
  gap: 8px !important;
  background: #72307f !important;
  border-radius: 2px !important;
} */
.btnCancel {
  padding: 12px 24px !important;
  gap: 8px !important;
  border-radius: 2px !important;
}
.helpTxt {
  font-family: monospace;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.03em;
  color: #000000 !important;
}
.helpSubTxt {
  font-family: monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */

  letter-spacing: -0.05em;

  color: #697280;
}
.chkHelp {
  background: #ffffff;
  border: 1px solid #d0d5dd;
}
#getHelp-title {
  /* font-family: "Roboto Mono"; */
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.05em;

  /* Palette/Transparent Black/100 */

  color: #000000;
}

#helpline-title {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.05em;

  /* Palette/Transparent Black/100 */

  color: #000000;
}
#getHelp-subtitle {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  letter-spacing: -0.05em;
  margin-top: 2px;
  color: #697280;
}
.lbl {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 520;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: -0.03em;
  color: #000000;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  gap: 8px;
  background: #ffffff;
  border: 1px solid #ececec !important;
  border-radius: 2px;
  box-shadow: none !important;
}
#assurence-text {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.05em;

  color: #ef8080;
}
.card {
  background: #ffffff !important;
  box-shadow: 0px 8.07619px 24.2286px rgba(0, 0, 0, 0.05) !important;
  border-radius: 2px !important;
}
::v-deep .v-text-field.v-text-field--solo .v-label {
  font-size: 12px !important;
}
.btnBorder1 {
  border: none !important;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(100px);
  opacity: 0;
}
::v-deep .v-list-item__content {
  text-align: start !important;
}
.blue--text {
  color: #61cae2 !important;
}
::v-deep .v-btn__content {
  line-height: unset;
}
.avatar-center-heigth {
  background: #231526;
}
.avttext {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 100%;
  /* identical to box height, or 17px */

  text-align: center;

  color: #ffffff;
}
</style>
