<template>
  <div class="row justify-center">
    <div class="block">
      <p class="digit">{{ days | two_digits }}</p>
      <p class="text">Days</p>
    </div>
    <div class="block">
      <p class="digit">{{ hours | two_digits }}</p>
      <p class="text">Hours</p>
    </div>
    <div class="block">
      <p class="digit">{{ minutes | two_digits }}</p>
      <p class="text">Minutes</p>
    </div>
    <div class="block">
      <p class="digit">{{ seconds | two_digits }}</p>
      <p class="text">Seconds</p>
    </div>
  </div>
</template>
<script>
export default {
  beforeDestroy() {
    clearInterval(this.getting);
  },
  destroyed() {
    clearInterval(this.getting);
  },
  mounted() {
    this.getting = window.setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000);
    }, 1000);
  },

  props: {
    date: {
      type: String,
    },
  },

  data() {
    return {
      getting: "",
      now: Math.trunc(new Date().getTime() / 1000),
    };
  },

  computed: {
    modifiedDate: function () {
      return Math.trunc(new Date(this.date) / 1000);
    },
    seconds() {
      return (this.modifiedDate - this.now) % 60;
    },

    minutes() {
      return Math.trunc((this.modifiedDate - this.now) / 60) % 60;
    },

    hours() {
      return Math.trunc((this.modifiedDate - this.now) / 60 / 60) % 24;
    },

    days() {
      return Math.trunc((this.modifiedDate - this.now) / 60 / 60 / 24);
    },
  },
};
</script>
<style lang="scss" scoped>
.block {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.text {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  /* identical to box height */

  color: rgba(255, 255, 255, 0.6);
  text-align: center;
}

.digit {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 56px;

  color: #ffffff;
}
</style>