import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#093c8e',
        secondary: '#b7d6fc',
        bgBackground: '#0B0B09',
        buttonBackground: '#6BCDE4',
        buttonText: '#100F10',
        title: '#F9F9F9',
        description: '#9D9D9D',
        white: '#FFFFFF',
        cyan: '#6BCDE4',
        privacy: '#6C6C6B',
        cardclr: '#100F10'
      },
    },
  },
})

export default vuetify