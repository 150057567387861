<template>
  <div class="body">
    <v-overlay :value="loading" class="text-center black--text">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <Component :is="layout">
      <router-view />
    </Component>
  </div>
</template>

<script>
import api from "@/services/api"
import licenseKey from "@/services/licenseKey"
import { EventBus } from "./main.js"
const defaultLayout = "default"

export default {
  created() {
    this.getToken()
    EventBus.$on("api-call-started", () => {
      this.loading = true
    })
    EventBus.$on("api-call-completed", () => {
      this.loading = false
    })
  },

  data: () => ({
    isLoaded: false,
    loading: false,
  }),
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + "-layout"
    },
  },

  methods: {
    getToken() {
      var input = {
        passCode: licenseKey.passCode,
        accessToken: licenseKey.localLicenseKey.toString(),
      }
      api
        .getToken(input)
        .then((resp) => {
          localStorage.setItem("accessToken", resp.data.token)
        })
        .catch((e) => {})
      this.isLoaded = true
    },
  },
}
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");

html,
.v-application {
  font-family: "Space Grotesk", sans-serif !important;
}
.body {
  font-family: "Space Grotesk" !important;
}
.btnBorder {
  border: 2px solid #000000 !important;
  border-radius: 80.7619px !important;
}
.btnBorder1 {
  padding: 12px 24px !important;
  gap: 8px !important;
  border-radius: 2px !important;
}
.btnSuccess {
  padding: 12px 24px !important;
  gap: 8px !important;
  background: #72307f !important;
  border-radius: 2px !important;
}
.v-alert {
  position: fixed !important;
  bottom: 10px !important;
  transform: translate(-12%, -21%) !important;
  margin: 0 auto !important;
  right: 0 !important;
  min-width: 300px !important;
  z-index: 10 !important;
}
::v-deep.v-messages__message {
  font-size: 14px !important;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(248, 246, 246);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.fontSize32 {
  font-size: 32px;
}
.fontSize40 {
  font-size: 40px;
}
.fontSize30 {
  font-size: 30px;
}
.fontSize28 {
  font-size: 28px;
}
.fontSize24 {
  font-size: 24px;
}
.fontweight100 {
  font-weight: 100 !important;
}
.fontweight500 {
  font-weight: 500 !important;
}
.fontweight700 {
  font-weight: 700 !important;
}
.back3 {
  background: antiquewhite !important;
}
.cursor {
  cursor: pointer;
}
.footer {
  margin-top: auto;
}
</style>
