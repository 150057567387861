<template>
  <div class="bg">
    <div class="container">
      <v-divider class="" color="#231526"></v-divider>
      <v-form ref="form1" v-model="valid1" lazy-validation :class="!isMobile ? 'pt-200 pt-10' : 'py-10'">
        <v-row class="px-0 txtStyle" no-gutters>
          <!-- <v-col cols="4" lg="1" md="4" class="text-start mt-3">
          <span @click="scrollToAboutSection" class="cursor">About us</span>
        </v-col> -->
          <v-col cols="12" lg="6" md="6" class="text-lg-start text-md-start text-center mt-3 d-flex">
            <span class="cursor pr-8" @click="showHelplineNo">Helpline </span>
            <span @click="openToContactUsDialog" class="cursor pr-8"> Contact us</span>
            <span @click="movetoTermsPolicy" class="cursor"> Terms & Policy</span>
          </v-col>
          <!-- <v-col
            cols="4"
            lg="1"
            md="4"
            class="text-lg-start text-md-start text-center mt-3"
            ><span class="cursor" @click="showHelplineNo">Helpline </span>
          </v-col>

          <v-col
            cols="4"
            lg="1"
            md="4"
            class="text-lg-start text-md-start text-end mt-3"
            ><span @click="openToContactUsDialog" class="cursor">
              Contact us</span
            ></v-col
          >
          <v-col
            cols="4"
            lg="2"
            md="4"
            class="text-lg-start text-md-start text-end mt-3"
            ><span @click="movetoTermsPolicy" class="cursor">
              Terms & Policy</span
            >
          </v-col> -->
          <v-spacer class="d-lg-block d-md-none d-none"></v-spacer>
          <v-col lg="3" md="3" cols="12" class="justify-end mt-lg-0 mt-md-4 mt-8 ml-0 d-lg-flex d-md-flex d-flex">
            <v-text-field
              label="email address"
              v-model="emailId1"
              single-line
              prepend-inner-icon="mdi-email"
              :rules="[rules.required, rules.email]"
              solo
              class="elevation-0 pr-lg-3 pr-md-3 pr-2"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn
              id="subcribebtn"
              large
              :loading="loading1"
              :disabled="loading1"
              @click="subscribeMail"
              class="white--text text-none elevation-0 py-6 px-8"
              >Subscribe for daily updates</v-btn
            ></v-col
          >
        </v-row>
      </v-form>
      <v-divider class="mt-3" color="#231526"></v-divider>
      <div class="text-lg-start text-center py-10 d-flex">
        <h5 class="textColor">
          <v-icon size="17" color="white">mdi-copyright</v-icon>
          {{ new Date().getFullYear() }} Digital Align Inc
        </h5>
        <v-divider></v-divider>
        <a href="https://digitalalign.com" target="_blank">
          <v-icon size="25" class="" color="white">mdi-web</v-icon></a
        >
        <a href="https://twitter.com/swaprogram" target="_blank"
          ><v-icon size="25" class="pl-8" color="white">mdi-twitter</v-icon></a
        >
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api"
export default {
  data: () => ({
    valid1: false,
    isMobile: false,
    loading1: false,
    emailId1: "",
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || "Invalid e-mail."
      },
    },
  }),

  beforeDestroy() {
    if (typeof window === "undefined") return

    window.removeEventListener("resize", this.onResize, { passive: true })
  },

  mounted() {
    this.onResize()

    window.addEventListener("resize", this.onResize, { passive: true })
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600
    },
    movetoTermsPolicy() {
      this.$router.push("/terms-privacy")
    },
    scrollToAboutSection() {
      this.$root.$emit("ScrollTo", true)
    },
    showHelplineNo() {
      this.$root.$emit("ShowHelplineNo", true)
    },
    scrollToContactusSection() {
      this.active = 4

      this.$root.$emit("ScrollToContactus", true)
    },
    openToContactUsDialog() {
      this.$root.$emit("emSubContactUs", true)
    },
    subscribeMail() {
      let valid = this.$refs.form1.validate()
      if (valid) {
        this.loading1 = true
        var input = { email: this.emailId1 }
        api
          .subscribeMail(input)
          .then((resp) => {
            if (resp.data.status == 200) {
              this.loading1 = false
              this.msg = "Successfully Subscribed"
              setTimeout(() => {
                this.emailId1 = ""

                this.msg = ""
                this.$refs.form1.reset()
                this.$refs.form1.resetValidation()
              }, 1000)
            }
          })
          .catch(() => {
            this.loading1 = false
            this.type = "error"
            this.msg = "Already Subscribed"
            this.dialog = false
            setTimeout(() => {
              this.msg = ""
              this.type = "success"
            }, 3000)
          })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.bg {
  margin-top: 0px;
  color: white !important;
  background: #0b0b09;
}
.px-200 {
  padding: 0 150px;
}
.textColor {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  color: #ffffff;
}
.py-25 {
  padding: 25px;
}
.subscribeBox {
  background: #ffffff !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04) !important;
  border-radius: 2px !important;
}
#subcribebtn {
  padding: 12px 24px;
  gap: 8px;

  background: #72307f;
  border-radius: 2px;
}
.txtStyle {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 18px !important;

  color: white !important;
}
</style>
