import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import vuetify from "./plugins/vuetify"
import VueSmoothScroll from "vue2-smooth-scroll"

import DefaultLayout from "@/components/layouts/DefaultLayout.vue"
import DreamLayout from "@/components/layouts/DreamLayout.vue"
import "@/assets/style/style.scss"
export const EventBus = new Vue()
Vue.component("default-layout", DefaultLayout)
Vue.component("dream-layout", DreamLayout)
Vue.config.productionTip = false
Vue.filter("two_digits", function (value) {
  if (value.toString().length <= 1) {
    return "0" + value.toString()
  }
  return value.toString()
})
Vue.use(VueSmoothScroll)
new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app")
