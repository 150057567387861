<template>
  <div class="termsbg">
    <div class="bg">
      <div class="container text-left">
        <v-btn text class="back mt-10" color="#FFFFFF" @click="goBack()">
          <v-icon left size="20"> mdi-chevron-left </v-icon>
          BACK
        </v-btn>
        <div class="termtitle mt-8 white--text">Terms and Privacy Policy.</div>
        <div class="desc mt-4 mb-11">
          Welcome to Swapna.org, a platform dedicated to facilitating women
          abuse incident reports and providing free digital arts to our users.
          By using our website, you agree to be bound by the terms and privacy
          policies.
        </div>
      </div>
    </div>
    <div class="container mt-15">
      <v-tabs
        dark
        background-color="transparent"
        color="primary"
        v-resize="onResize"
        v-model="currentItem"
        row
        wrap
        class="mb-3"
        :vertical="$vuetify.breakpoint.mobile"
      >
        <v-tabs-slider color="transparent"></v-tabs-slider>
        <v-tab
          v-for="(item, index) in tabs"
          :key="index"
          :href="'#tab-' + item.name"
          :title="item.title"
          :class="index < tabs.length - 1 ? 'tabsborder' : ''"
        >
          {{ item.name }}
        </v-tab>
        <v-menu v-if="more.length" bottom class="ml-3" offset-y>
          <template v-slot:activator="{ on }">
            <v-btn text class="align-self-center" v-on="on">
              more
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>

          <v-list class="grey lighten-3">
            <v-list-item
              v-for="(item, index) in more"
              :key="index"
              @click="addItem(item)"
            >
              {{ item.name }}
            </v-list-item>
          </v-list>
        </v-menu>
      </v-tabs>
      <v-tabs-items v-model="currentItem" height="auto" dark>
        <v-tab-item
          key="Terms and Conditions"
          value="tab-Terms and Conditions"
          height="auto"
          class="tabborder termsbg"
        >
          <TermsAndConditions></TermsAndConditions>
        </v-tab-item>

        <v-tab-item
          key="Privacy Policy"
          value="tab-Privacy Policy"
          height="auto"
          class="termsbg"
        >
          <PrivacyPolicy></PrivacyPolicy>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>
<script>
import TermsAndConditions from "../components/TermsAndConditions.vue";
import PrivacyPolicy from "../components/PrivacyPolicy.vue";
export default {
  components: {
    TermsAndConditions,
    PrivacyPolicy,
  },
  computed: {},
  data: () => ({
    tab: null,
    tabItems: [],
    tabs: [],
    more: [],
    currentItem: "tab-Terms and Conditions",
  }),
  created() {
    this.tabItems = [
      {
        name: "Terms and Conditions",
      },
      {
        name: "Privacy Policy",
      },
    ];
  },
  methods: {
    addItem(item) {
      const removed = this.tabs.splice(this.tabs.length - 1, 1);
      this.tabs.push(...this.more.splice(this.more.indexOf(item), 1));
      this.more.push(...removed);
      this.$nextTick(() => {
        this.currentItem = "tab-" + item;
      });
    },
    onResize() {
      const temp = this.tabItems.slice();
      this.tabs = temp.splice(0, window.innerWidth / 100 - 1);
      this.more = temp.splice(0);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  background-color: #231526;
}
.termtitle {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 51px;
}
.desc {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  /* or 23px */

  font-feature-settings: "pnum" on, "lnum" on, "ss01" on, "liga" off;

  color: #ffffff;
}
.back {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 23px !important;
  /* identical to box height */
  padding-left: 0px !important;
  display: flex !important;
  align-items: center !important;

  color: #ffffff !important;
}
// /deep/.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
//   > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(
//     .v-slide-group--has-affixes
//   )
//   .v-slide-group__prev {
//   display: contents;
//   visibility: hidden;
// }
/deep/.v-tab {
  padding: 0px 8px;
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: rgba(255, 255, 255, 0.2);
}
/deep/.v-menu__content {
  min-width: 99px;
  top: 120px;
  left: 190px;
}
.termsbg {
  background-color: #0b0b09;
}
.theme--light.v-tabs-items {
  overflow: visible;
}

.v-tab {
  text-transform: none !important;
  letter-spacing: 0 !important;
  padding-right: 24px;
}
.tabborder {
  // background: $white  !important;
  // border: 1px solid $border  !important;
  // border-color: $border  !important;
  // border-radius: 4px !important;
  color: #ffffff !important;
}

.theme--dark.v-tabs > .v-tabs-bar .v-tab--disabled,
.theme--dark.v-tabs > .v-tabs-bar .v-tab--active,
.theme--dark.v-tabs > .v-tabs-bar .v-tab--active > .v-btn,
.theme--dark.v-tabs > .v-tabs-bar .v-tab--active > .v-icon {
  color: #ffffff;
}
.tabsborder {
  background: url(../assets/line.png) center right no-repeat;
  margin-right: 20px;
}
</style>