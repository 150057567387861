import Vue from "vue"
import VueRouter from "vue-router"
import HomeView from "../views/HomeView.vue"
import Login from "../views/Login.vue"
import Verify from "../views/Verify.vue"
import RevealDreamArt from "@/views/RevealDreamArt.vue"

import DreamArt from "../views/DreamArt.vue"
import LoginOrRegister from "../views/LoginOrRegister.vue"
import TermsAndPrivacy from "../views/TermsAndPrivacy.vue"
import MyAccount from "../views/MyAccount.vue"
import Popup from "../views/Popup.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      isHide: false,
      isHome: true
    },
  },
  {
    path: "/verify",
    name: "Verify",
    component: Verify,
    meta: {
      isHide: false,
    },
  },

  {
    path: "/dream-art",
    name: "dreamart",
    component: DreamArt,
  },
  {
    path: "/terms-privacy",
    name: "termsprivacy",
    component: TermsAndPrivacy,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      isHide: true,
    },
  },
  {
    path: "/reveal-dream",
    name: "RevealDreamArt",
    component: RevealDreamArt,
    meta: {
      isHide: true,
      layout: "dream",
    },
  },
  {
    path: "/login-or-register",
    name: "LoginOrRegister",
    component: LoginOrRegister,
    meta: {
      hideFooter: true,
      layout: "dream",
    },
  },

  {
    path: "/my-account",
    name: "MyAccount",
    component: MyAccount,
    meta: {
      hideFooter: true,
      layout: "dream",
    },
  },
  {
    path: "/popup",
    name: "Popup",
    component: Popup,
    meta: {
      isHide: true,
    },
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0)
  },
  behavior: "smooth",
})

export default router
