<template>
  <div :class="!isMobile ? 'container-fluid mb-0' : 'px-4'">
    <v-row>
      <v-col>
        <v-card min-height="980px" class="elevation-0">
          <v-card-text :class="!isMobile ? 'px-8 py-10' : 'pa-5'">
            <v-row>
              <v-col class="text-start d-flex flex-column txtStyle" lg="2" sm="5" cols="12"
                >Reported till now
                <span id="totReportCount" class="mt-4">{{ totalReports }}</span>
                <p v-if="!reportIn" class="mt-4">
                  <v-icon color="#34C759" size="18">mdi-arrow-bottom-right</v-icon> +{{ reportPerc }}% this week
                </p>
                <p v-if="reportIn" class="mt-4">
                  <v-icon color="#B91F3F" size="18">mdi-arrow-top-left</v-icon> +{{ reportPerc }}% this week
                </p>
              </v-col>
              <v-col lg="1" class="align-center" sm="2"
                ><v-divider vertical v-if="!isMobile" class="divider my-5"></v-divider
                ><v-divider v-if="isMobile" class="divider py-0"></v-divider>
              </v-col>
              <v-col lg="3" class="text-start d-flex flex-column txtStyle1" sm="5" cols="12">
                {{ menuItems[selectedIndex]?.name }}
                <span id="totReportCount" class="mt-4" v-if="selectedIndex != 5">{{
                  items.filter((ele) => ele.typeDetailsId == menuItems[selectedIndex].id).length
                }}</span>
                <p v-if="!selectedItemWeekRecordIn && selectedIndex != 5" class="mt-4 txtStyle">
                  <v-icon color="#34C759" size="18">mdi-arrow-bottom-right</v-icon> +{{ selectedItemWeekPerc }}% this
                  week
                </p>
                <p v-if="selectedItemWeekRecordIn && selectedIndex != 5" class="mt-4 txtStyle">
                  <v-icon color="#B91F3F" size="18">mdi-arrow-top-left</v-icon> +{{ selectedItemWeekPerc }}% this week
                </p>
              </v-col>
              <v-col
                :class="!isMobile ? 'align-end d-flex flex-column' : 'align-start d-flex flex-column py-0'"
                lg="6"
                cols="12"
                md="6"
                sm="12"
              >
                <span class="text">last updated {{ new Date().toDateString() }}</span>
                <div class="mt-10 d-flex">
                  <span :class="!isMobile ? 'txtStyle text-end mr-4' : 'txtStyle text-start mr-1 '"
                    >select intensity</span
                  >
                  <v-chip-group :active-class="'chipHover' + selectedIndex" column v-model="selectedIndex">
                    <v-chip
                      v-for="(color, index) in colorList"
                      :key="index"
                      :color="color"
                      :class="index == 0 ? 'ml-1' : 'ml-0'"
                    >
                    </v-chip>
                  </v-chip-group>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <div id="map" style="height: 1000px"></div>
        </v-card>
        <!-- <v-form ref="form1" v-model="valid1" lazy-validation>
          <v-row class="px-0 txtStyle mt-8" no-gutters>
            <v-col cols="4" lg="1" md="1" class="text-start mt-3">
              <span>about us</span>
            </v-col>
            <v-col cols="4" lg="1" md="1" class="text-start mt-3">helpline</v-col>
            <v-col cols="4" lg="3" md="2" class="text-start mt-3"
              ><span @click="dialog = true" class="cursor"> plugin key</span></v-col
            >
            <v-col lg="4" md="6" cols="12" class="justify-end px-1 mt-lg-0 mt-md-0 mt-3">
              <v-text-field
                label="email address"
                v-model="emailId1"
                dense
                single-line
                prepend-inner-icon="mdi-email"
                solo
                :rules="[rules.required, rules.email]"
                class="elevation-0"
              ></v-text-field
            ></v-col>
            <v-col lg="3">
              <v-btn
                id="subcribebtn"
                large
                :loading="loading1"
                :disabled="loading1"
                @click="subscribeMail"
                class="white--text text-none elevation-0"
                >subscribe for daily updates</v-btn
              >
            </v-col>
          </v-row>
        </v-form> -->
      </v-col>
    </v-row>
    <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="dialog">
      <v-card>
        <v-toolbar class="elevation-0 font-weight-bold">Plugin Subscription</v-toolbar>
        <v-divider class="my-0 pa-0"></v-divider>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <div class="text-h2 pa-5">
              <v-text-field
                v-model="emailId"
                label="Email Id"
                placeholder="Email Id"
                :rules="[rules.required, rules.email]"
              ></v-text-field>
              <v-text-field
                v-model="siteUrl"
                label="Site Url"
                placeholder="Site Url"
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                v-if="isSent"
                v-model="otp"
                label="One Time Password"
                placeholder="One Time Password"
                :rules="[rules.required]"
              ></v-text-field>
            </div>
            <v-card-actions class="justify-end py-5">
              <v-spacer></v-spacer>
              <v-btn color="#EBEBEB" class="text-capitalize btnCancel black--text" depressed @click="dialog = false">
                Cancel
              </v-btn>
              <v-btn
                v-if="!isSent"
                color="#72307F"
                class="white--text btnSuccess elevation-0"
                :disabled="loading"
                @click="subscribe"
                :loading="loading"
              >
                Submit
              </v-btn>
              <v-btn
                v-if="isSent"
                color="#72307F"
                class="white--text"
                :disabled="loading"
                @click="verify"
                :loading="loading"
              >
                Verify
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>

    <v-alert v-if="msg" :type="type">
      {{ msg }}
    </v-alert>
  </div>
</template>

<script>
import mapApi from "@/services/apiLicense.js"
import api from "@/services/api"
import { Loader } from "@googlemaps/js-api-loader"
const loader = new Loader({
  apiKey: mapApi.apiKey,
})
export default {
  mounted() {
    //  this.getReport();
    this.loadmap()
    if (this.selectedIndex != 5) {
      this.analyzedata(this.items.filter((ele) => ele.typeDetailsId == this.menuItems[0].id))
    }

    this.onResize()
    window.addEventListener("resize", this.onResize, { passive: true })
  },
  props: {
    menuItems: { type: Array },
  },
  beforeDestroy() {
    if (typeof window === "undefined") return

    window.removeEventListener("resize", this.onResize, { passive: true })
  },
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || "Invalid e-mail."
      },
    },
    isMobile: false,
    dialog: false,
    reportIn: false,
    items: [],
    totalReports: 0,
    prevWeekReports: 0,
    currWeekReports: 0,
    reportPerc: null,
    selectedIndex: 5,
    selectedItem: [],
    colorList: ["#FDF2D0", "#F5C242", "#F8A262", "#EF8080", "#B91F3F", "#fffff"],
    prevWeekCount: 0,
    selectedItemWeekPerc: 0,
    selectedItemWeekRecordIn: false,
    msg: "",
    type: "success",
    emailId: "",
    emailId1: "",
    siteUrl: "",
    otp: "",
    loading: false,
    loading1: false,
    valid: false,
    valid1: false,
    isSent: false,
    msg1: "",
    items1: [],
  }),
  watch: {
    selectedIndex: function (val) {
      if (this.selectedIndex != 5) {
        this.analyzedata(this.menuItems[val].id)
      } else {
        this.selectedItem = []
      }
      this.loadmap()
    },
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600
    },
    subscribeMail() {
      let valid = this.$refs.form1.validate()
      if (valid) {
        this.loading1 = true
        var input = { email: this.emailId1 }
        api
          .subscribeMail(input)
          .then((resp) => {
            if (resp.data.status == 200) {
              this.loading1 = false
              this.msg = "Successfully Subscribed"
              setTimeout(() => {
                this.emailId1 = ""
                this.msg = ""
              }, 4000)
            }
          })
          .catch(() => {
            this.loadin1g = false
            this.type = "error"
            this.msg = "Already Subscribed"
            this.dialog = false
            setTimeout(() => {
              this.msg = ""
              this.type = "success"
            }, 3000)
          })
      }
    },
    subscribe() {
      let valid = this.$refs.form.validate()
      if (valid) {
        this.loading = true
        var input = { email: this.emailId, siteUrl: this.siteUrl }
        api
          .subscribePlugin(input)
          .then((resp) => {
            if (resp.data.status == 200) {
              this.loading = false
              this.isSent = true
              this.msg = "Successfully Submitted.Please Verify your mail to get the access key"
              setTimeout(() => {
                this.msg = ""
                this.emailId = ""
                this.siteUrl = ""
                this.otp = ""
              }, 4000)
            }
          })
          .catch((e) => {
            this.isSent = false
            this.type = "error"
            this.msg = e.response
            this.dialog = false
            setTimeout(() => {
              this.msg = ""
              this.type = "success"
              this.loading = false
            }, 3000)
          })
      }
    },
    verify() {
      this.loading = true
      let valid = this.$refs.form.validate()
      if (valid) {
        var input = { email: this.emailId, code: this.otp }
        api
          .verifyToken(input)
          .then((resp) => {
            if (resp.data.status == 200) {
              this.dialog = false
              this.isSent = true
              this.msg = "Successfully Verified"
              setTimeout(() => {
                this.msg = ""
                this.loading = false
              }, 4000)
            } else {
              this.type = "error"
              this.msg = "Invalid code"
              setTimeout(() => {
                this.type = "success"
                this.msg = ""
                this.loading = false
              }, 6000)
            }
          })
          .catch((e) => {
            this.isSent = false
            this.type = "error"
            this.msg = e.response
            this.dialog = false
            setTimeout(() => {
              this.msg = ""
              this.type = "success"
              this.loading = false
            }, 3000)
          })
      }
    },
    analyzedata(val) {
      var data = this.items.filter((ele) => ele.typeDetailsId == val)
      this.selectedItem = data
      // data = data.sort(function (a, b) {
      //   a.submittedOn - b.submittedOn;
      // });
      var d = new Date().getDay()
      var diff = 7 - d
      var startDate = new Date()
      startDate.setDate(startDate.getDate() - (d == 7 ? 7 : d - diff))
      var endDate = new Date()
      endDate.setDate(new Date(startDate).getDate() + (d == 7 ? 7 : d))
      var prevStartDate = new Date()
      var prevEndDate = startDate
      prevStartDate.setDate(prevEndDate.getDate() - (d == 7 ? 7 : d + diff))
      var data1 = data.filter(
        (ele) => new Date(ele.submittedOn) >= new Date(startDate) && new Date(ele.submittedOn) <= new Date(endDate)
      )
      var data2 = data.filter(
        (ele) =>
          new Date(ele.submittedOn) >= new Date(prevStartDate) && new Date(ele.submittedOn) <= new Date(prevEndDate)
      )

      if (data1.length > data2.length) {
        this.selectedItemWeekRecordIn = true
        this.selectedItemWeekPerc = data1.length > 0 ? ((100 * data1.length) / this.totalReports).toFixed(2) : 0.0
      } else {
        this.selectedItemWeekRecordIn = false
        this.selectedItemWeekPerc = data2.length > 0 ? ((100 * data2.length) / this.totalReports).toFixed(2) : 0.0
      }
    },
    getWeeklyReport() {
      api.getWeeklyReport().then((resp) => {
        this.currWeekReports = resp.data.currWeekReports
        this.prevWeekReports = resp.data.prevWeekReports
        if (this.currWeekReports > this.prevWeekReports) {
          this.reportIn = true
          this.reportPerc = ((100 * this.currWeekReports) / this.totalReports).toFixed(2)
        } else {
          this.reportIn = false
          this.reportPerc = ((100 * this.prevWeekReports) / this.totalReports).toFixed(2)
        }
        this.$emit("weeklyReport", {
          currWeekReports: this.currWeekReports,
          prevWeekReports: this.prevWeekReports,
          totalReports: this.totalReports,
        })
      })
    },
    getTotalReport() {
      api.getReport().then((resp) => {
        if (this.selectedItem.length > 0) {
          this.items1 = this.selectedItem
        }
        this.items = resp.data.records
        this.totalReports = resp.data.total
      })
      this.loadmap()
    },
    getReport(google) {
      api.getReport().then((resp) => {
        if (this.selectedItem.length > 0) {
          this.items1 = this.selectedItem
        } else {
          this.items = resp.data.records
          this.items1 = this.items
        }
        this.totalReports = resp.data.total
        this.getWeeklyReport()

        var heatmapData = []
        var theStareItms = []
        var verbalAbuseItm = []
        var shovingAroundItm = []
        var seriousInjuryItm = []
        var lifeThreateningItm = []
        this.items1
          .filter((element) => element.name == "the stare")
          .forEach((ele) => {
            theStareItms.push(new google.maps.LatLng(ele.latitude, ele.longitude))
          })
        this.items1
          .filter((element) => element.name == "verbal abuse")
          .forEach((ele) => {
            verbalAbuseItm.push(new google.maps.LatLng(ele.latitude, ele.longitude))
          })
        this.items1
          .filter((element) => element.name == "shoving around")
          .forEach((ele) => {
            shovingAroundItm.push(new google.maps.LatLng(ele.latitude, ele.longitude))
          })
        this.items1
          .filter((element) => element.name == "serious injuries")
          .forEach((ele) => {
            seriousInjuryItm.push(new google.maps.LatLng(ele.latitude, ele.longitude))
          })
        this.items1
          .filter((element) => element.name == "life threatening")
          .forEach((ele) => {
            lifeThreateningItm.push(new google.maps.LatLng(ele.latitude, ele.longitude))
          })

        this.items.forEach((ele) => {
          heatmapData.push(new google.maps.LatLng(ele.latitude, ele.longitude))
        })
        var mapCenter = !this.isMobile
          ? new google.maps.LatLng(40.463667, -3.74922)
          : new google.maps.LatLng(20.593684, 78.96288)

        var map = new google.maps.Map(document.getElementById("map"), {
          center: mapCenter,
          zoom: 3,
          mapTypeId: "terrain",
        })
        // var heatmap = new google.maps.visualization.HeatmapLayer({
        //   data: heatmapData,
        //   radius: 10,
        //   map: map,
        // });

        // heatmap.setMap(map);
        // const gradient = [
        //   "rgba(243, 100, 100, 0)",
        //   "rgba(255, 0, 0, 1)",
        //   "rgba(255, 0, 0, 1)",
        //   "rgba(255, 0, 0, 1)",
        // ];

        // heatmap.set("gradient", gradient);
        //The stare
        var theStareHeatmap = new google.maps.visualization.HeatmapLayer({
          data: theStareItms,
          radius: 10,
          map: map,
        })
        if (theStareItms.length > 0) {
          theStareHeatmap.setMap(map)
          const gradient1 = ["rgba(243, 100, 100, 0)", "rgba(252, 249, 191, 1)", "rgba(252, 249, 191, 1)"]

          theStareHeatmap.set("gradient", gradient1)
        }
        //verbalAbuseItm
        var verbalAbuseHeatmap = new google.maps.visualization.HeatmapLayer({
          data: verbalAbuseItm,
          radius: 10,
          map: map,
        })
        if (verbalAbuseItm.length > 0) {
          verbalAbuseHeatmap.setMap(map)
          const gradient2 = ["rgba(243, 100, 100, 0)", "rgba(255,119,17, 1)", "rgba(255,119,17, 1)"]
          verbalAbuseHeatmap.set("gradient", gradient2)
        }
        //shovingAroundItm
        var shovingAroundHeatmap = new google.maps.visualization.HeatmapLayer({
          data: shovingAroundItm,
          radius: 10,
          map: map,
        })
        if (shovingAroundItm.length > 0) {
          shovingAroundHeatmap.setMap(map)
          const gradient3 = [
            "rgba(243, 100, 100, 0)",
            "rgba(252,182,4, 1)",
            "rgba(252,182,4, 1)",
            "rgba(252,182,4, 1)",
            "rgba(252,182,4, 1)",
            "rgba(252,182,4, 1)",
          ]
          shovingAroundHeatmap.set("gradient", gradient3)
        }
        //seriousInjuryItm
        var seriousInjuryHeatmap = new google.maps.visualization.HeatmapLayer({
          data: seriousInjuryItm,
          radius: 10,
          map: map,
        })
        if (seriousInjuryItm.length > 0) {
          seriousInjuryHeatmap.setMap(map)
          const gradient4 = [
            "rgba(243, 100, 100, 0)",
            "rgba(255, 0, 0, 1)",
            "rgba(255, 0, 0, 1)",
            "rgba(255, 0, 0, 1)",
            "rgba(255, 0, 0, 1)",
            "rgba(255, 0, 0, 1)",
            "rgba(255, 0, 0, 1)",
            "rgba(255, 0, 0, 1)",
            "rgba(255, 0, 0, 1)",
            "rgba(255, 0, 0, 1)",
            "rgba(255, 0, 0, 1)",
          ]

          seriousInjuryHeatmap.set("gradient", gradient4)
        }
        //lifeThreateningItm
        var lifeThreateningHeatmap = new google.maps.visualization.HeatmapLayer({
          data: lifeThreateningItm,
          radius: 10,
          map: map,
        })
        if (lifeThreateningItm.length > 0) {
          lifeThreateningHeatmap.setMap(map)
          const gradient5 = ["rgba(243, 100, 100, 0)", "rgba(185, 31, 63, 1)", "rgba(185, 31, 63, 1)"]

          lifeThreateningHeatmap.set("gradient", gradient5)
        }
        // google.maps.event.addListener(map, "click", function (event) {
        //   alert(
        //     "Latitude: " + event.latLng.lat() + " " + ", longitude: " + event.latLng.lng()
        //   );
        // });
      })
    },
    loadmap() {
      loader
        .load()
        .then((google) => {
          this.getReport(google)
        })
        .catch((e) => {})
      /* Data points defined as an array of LatLng objects */
    },
  },
}
</script>
<style lang="scss" scoped>
.container-fluid {
  padding: 0 200px;
}
.subscribeBox {
  background: #ffffff !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04) !important;
  border-radius: 2px !important;
}
#subcribebtn {
  padding: 12px 24px;
  gap: 8px;

  background: #72307f;
  border-radius: 2px;
}
.txtStyle {
  font-family: monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 167% */

  letter-spacing: -0.05em;

  color: #697280;
}
.txtStyle1 {
  font-family: monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 167% */

  letter-spacing: -0.05em;

  color: #b91f3f;
}
#totReportCount {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 28px;
  /* identical to box height, or 100% */

  letter-spacing: -0.05em;

  color: #000000;
}
.divider {
  min-height: 50% !important;
}
.text {
  font-family: monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.05em;

  color: #697280;
}
::v-deep .v-chip.v-size--default {
  width: 12px;
  height: 12px;
  padding: 8px;
  cursor: pointer;
}

.btnSuccess {
  padding: 12px 24px !important;
  gap: 8px !important;
  background: #72307f !important;
  border-radius: 2px !important;
}
.map {
  height: 100%;
  width: 100%;
  overflow: visible !important;
}
.chipHover0 {
  box-shadow: 0px 0px 0px 2px #eaccd2 !important;
}
.chipHover1 {
  box-shadow: 0px 0px 0px 2px #f9b47f !important;
}
.chipHover2 {
  box-shadow: 0px 0px 0px 2px #eac872 !important;
}
.chipHover3 {
  box-shadow: 0px 0px 0px 2px #ad8e30 !important;
}
.chipHover4 {
  box-shadow: 0px 0px 0px 2px #e6abab !important;
}
.chipHover5 {
  box-shadow: 0px 0px 0px 2px #e6abab !important;
}
::v-deep .v-chip.v-size--default {
  border-radius: 0px !important;
}
::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: none;
}
::v-deep .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
  padding: 8px 0px;
}
.cursor {
  cursor: pointer;
}
</style>
