<template>
  <div :class="!isMobile ? 'container-fluid mb-0 ' : 'px-4'">
    <h2 class="black--text mb-6 text-center">About Us</h2>

    <v-img
      :src="require('@/assets/images/home/aboutus_bg.png')"
      class="px-2 align-center"
      :max-height="!isMobile ? '620px' : '980px'"
    >
      <div
        class="text-start justify-start col-lg-6 col-12 fontSize32 white--text d-flex font-weight-light flex-column px-lg-16 px-md-10 px-4"
      >
        <v-icon color="#72307F" size="50" class="d-block"> mdi-format-quote-open </v-icon>
        <p class="fontweight100">
          <span class="heroText font-weight-bolder">Swapna</span> is a name derived <br />from the
          <span class="heroText">sanskrit</span> word which means
          <span class="fontSize40 font-weight-bold">dream.</span> ”
        </p>
        <p class="fontSize24 pt-2">
          unfortunately many live a
          <span class="font-weight-bold">“Blindfolded Dream"</span>
        </p>
      </div>
      <v-img v-if="isMobile" :src="require('@/assets/img/about-us-women_background-img.png')"></v-img>
    </v-img>
    <div class="mt-15 text-start">
      <h1 class="fontweight500 pb-5">
        Welcome to
        <span class="color1 font-weight-bold">Swapna.org</span>
      </h1>
      <p class="font-weight-bold welcm-subText">
        We are a team of advocates and technologists working to end domestic violence and abuse against women. Our
        mission is to provide a safe, anonymous, and accessible platform for survivors and witnesses to report incidents
        of abuse and seek help.<br />
        <br />
        Our website is designed to be user-friendly and intuitive, with resources and tools to help survivors navigate
        the process of seeking help and support. We understand that reporting abuse can be a difficult and overwhelming
        process, and we are here to provide guidance and support every step of the way.
      </p>
    </div>
    <v-row no-gutters class="text-lowercase">
      <v-col cols="12" class="text-start align-baseline d-flex pt-6">
        <div><v-icon class="mt-4 px-2" color="#72307f">mdi-check-all</v-icon></div>
        <p>
          One of the unique features of our website is our location-based tracking system, which allows users to report
          incidents of domestic violence in their specific location and see a map of incidents in their community. This
          helps us identify areas where there may be higher levels of abuse and alert authorities to take action to
          address the problem.
        </p>
      </v-col>
      <v-col cols="12" class="text-start align-baseline d-flex pt-6">
        <div><v-icon class="mt-2 px-2" color="#72307f">mdi-check-all</v-icon></div>
        <p>
          We believe that by working together, we can remove the blindfold and create a world where women can live free
          from violence and abuse. Thank you for joining us in this important mission.
        </p>
      </v-col>
      <v-col cols="12" class="text-start align-baseline d-flex pt-6">
        <div><v-icon class="mt-2 px-2" color="#72307f">mdi-check-all</v-icon></div>
        <p>
          We encourage all citizens of the world to make one click to share if they see, experience or even hear about
          domestic violence in their location. Our goal is to add the "Swapna.org" icon on every website in the world,
          so people can easily report abuse without the worry of being noticed. If you are an organization that mainly
          focuses on preventing abuse and supporting victims of abuse, or if you are a website who would like to support
          our great cause, please contact us by completing this form.
        </p>
      </v-col>
    </v-row>
    <!-- <v-divider class="mt-100"></v-divider> -->
  </div>
</template>
<script>
import api from "@/services/api"

export default {
  beforeDestroy() {
    if (typeof window === "undefined") return
    window.removeEventListener("resize", this.onResize, { passive: true })
  },
  data() {
    return {
      isMobile: false,
      hover: true,
      valid: false,
      dialog: false,
      form: {
        fullName: "",
        email: "",
        phone: "",
        purpose: "",
        address: "",
        website: "",
        contactPersonName: "",
        orgName: "",
        details: "",
      },
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || "Invalid e-mail."
        },
      },
      purposeList: ["Prevent Women Abuse", "Support Women", "Participate in this Cause", "Other"],
      loading: false,
    }
  },
  mounted() {
    this.onResize()
    window.addEventListener("resize", this.onResize, { passive: true })
    this.$root.$on("ScrollToContactus", (val) => {
      if (val) {
        this.scroll()
      }
    })
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth <= 800
    },
    scroll() {
      const element = document.getElementById("contact-us")
      element.scrollIntoView({ behavior: "smooth" })
    },
    addContactUs() {
      let valid = this.$refs.form1.validate()
      if (valid) {
        this.loading = true
        api.addContactUsData(this.form).then((resp) => {
          this.dialogContactUs = false
          this.dialog1 = true
          this.loading = false
          this.form = {
            fullName: "",
            email: "",
            phone: "",
            website: "",
            purpose: "",
            contactPersonName: "",
            orgName: "",
            address: "",
            details: "",
          }
          this.$refs.form1.reset()
          this.$refs.form1.resetValidation()
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.content-padding {
  padding: 200px !important;
}
.container-fluid {
  padding: 0 200px;
}
::v-deep .v-image__image--contain {
  opacity: 0.5 !important;
}
.heroText {
  font-style: normal;
  font-weight: 700;

  line-height: 38px;
  /* or 119% */

  color: #ffffff;
}

.mainTxt {
  font-weight: bold;
  text-transform: none;
}
.subText {
  font-size: 19px;
  text-transform: none;
  font-weight: 500;
}
.color1 {
  color: #72307f;
}

#assurence-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.05em;

  color: #ef8080;
}
::v-deep .v-list-item__content {
  text-align: start !important;
}
.welcm-subText {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: justify;
  text-transform: lowercase;

  color: #212121;
}

.mt-100 {
  margin-top: 200px !important;
}

@media only screen and (min-width: 1440px) and (max-width: 1919px) {
  .container-fluid {
    padding: 0 150px;
  }
}
@media only screen and (min-width: 1023px) and (max-width: 1439px) {
  .container-fluid {
    padding: 0 150px;
  }
}
</style>
