<template>
  <v-app>
    <Header header-color="white" />
    <v-main class="main-style">
      <slot />
    </v-main>
    <Footer v-if="$route.meta.isHome" />
    <FooterLarge v-else />
  </v-app>
</template>

<script>
import Header from "@/components/shared/Header.vue";
import FooterLarge from "@/components/shared/FooterLarge.vue";
import Footer from "@/components/shared/Footer.vue";
export default {
  components: {
    Header,
    Footer,
    FooterLarge,
  },
};
</script>

<style scoped>
.main-style {
  background: #fbf3f0 !important;
}
</style>
