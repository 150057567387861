<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <div class="text-center justify-center d-flex align-center flex-column emptyCard">
        <div class="pa-4 borderclass d-flex justify-center">
          <div
            class="cardbg d-flex flex-column align-center justify-center elevation-1 cursor"
            v-if="!revealed"
            @click="revealed ? '' : getdreamcard()"
          >
            <h2 class="pb-10">Click Here!</h2>
            <div class="skeleton"></div>
            <img :src="require('@/assets/dream-art/lightlogo.png')" />
          </div>
          <div
            v-else
            class="d-flex align-center justify-center cardbgreveal"
            width="300"
            height="368"
            style="position: relative"
          >
            <lottie-player
              src="https://assets8.lottiefiles.com/packages/lf20_lmggs7gq.json"
              background="transparent"
              speed="1"
              style="position: absolute; left: -40%; top: -40%; z-index: 10"
              loop
              autoplay
            ></lottie-player>
            <v-img
              :src="require('@/assets/dream-art/badge.svg')"
              style="position: absolute; right: -30%; top: -30%; z-index: 1"
              cover
            ></v-img>
            <v-img :src="dreamArt.dreamArtUrl" cover>
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey-lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </div>
        </div>
        <div class="mt-9 cardtext white--text" v-if="!revealed">Celebration of Women's Dreams</div>
        <div v-else class="mt-9 cardtext white--text">SWAPNA DREAMS - {{ dreamArt.dreamArtName.split(".")[0] }}</div>

        <div class="mt-2 carddesc" v-if="!revealed">kindly give us moment, finding a dream for you</div>
        <div v-else class="mt-2 carddesc">Hurray!, this can be your dream.</div>
        <div class="d-flex mt-12" :class="{ invisible: !revealed }">
          <v-btn
            tile
            color="buttonBackground"
            @click="downloadImage()"
            class="buttonText--text text-none txtStyle btn-block elevation-0 mr-6"
            >Download
          </v-btn>

          <v-btn tile color="buttonText" class="white--text text-none txtStyle btn-block elevation-0" to="/my-account"
            >My Account
          </v-btn>
        </div>
      </div>
      <v-snackbar v-model="snackbar" :timeout="timeout">
        {{ text }}

        <template v-slot:actions>
          <v-btn color="blue" variant="text" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-layout>
  </v-container>
</template>

<script>
import api from "@/services/api"
import FileSaver from "file-saver"

export default {
  name: "RevealDream",
  data: () => ({
    revealed: false,
    date: new Date().toISOString().substr(0, 10),
    socialDate: new Date("2023-03-21"),
    snackbar: false,
    text: "Please wait until 21st March",
    timeout: 2000,
    dreamArt: "",
  }),

  methods: {
    async getdreamcard() {
      if (this.issocialday) {
        let result = await api.assignDreamCollection(sessionStorage.getItem("id"))
        if (result.data.status == 200) {
          if (result.data.isAlreadyClaimed) {
            this.$router.push("/my-account")
          } else {
            this.dreamArt = result.data.data
            sessionStorage.setItem("isClaimed", true)
            this.revealed = true
          }
        } else {
          this.snackbar = true
          this.text = "Something went wrong.Please try again"
        }
      } else {
        this.snackbar = true
        this.revealed = false
      }
    },
    async downloadImage() {
      let result = await api.downloadDreamImage(this.dreamArt.dreamArtName)
      if (result) {
        let image = `data:image/png;base64,${result.data.data}`
        FileSaver.saveAs(image, result.data.imageName)
      }
    },
  },
  computed: {
    issocialday() {
      if (new Date(this.date).getTime() >= new Date(this.socialDate).getTime()) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.emptyCard {
  padding: 0px;
  margin-top: 150px;
  margin-bottom: 50px;
}
.txtStyle {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 22px !important;
}
::v-deep .v-btn {
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.purplebg {
  background: rgb(114, 48, 127) !important;
  background: radial-gradient(circle, rgba(114, 48, 127, 0.2) 0%, rgba(0, 0, 0, 1) 50%) !important;
  mix-blend-mode: normal;
  opacity: 0.8;
  backdrop-filter: blur(162.955px);
}
.borderclass {
  border: 3px solid rgba(255, 255, 255, 0.1);
  width: 300px;
}
.cardbg {
  background: linear-gradient(180deg, #d6d4d4 0%, rgba(255, 255, 255, 0.526042) 47.4%, rgba(255, 255, 255, 0) 100%);
  mix-blend-mode: soft-light;
  width: 300px;
  height: 368px;
  overflow: hidden;
  position: relative;
}

.cardbgreveal {
  width: 268px;
  height: 334px;
}
.cardtext {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
}
.carddesc {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}

::v-deep .v-btn:not(.v-btn--round).v-size--default {
  text-transform: none;
  height: auto;
}
.invisible {
  visibility: hidden;
}
.skeleton {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent 0%, rgba(255, 255, 255, 0.5) 50%, transparent 100%);
  animation: skeleton 2s ease-in-out infinite;
}

@keyframes skeleton {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}
</style>
