import axios from "./axios-instance"
import { EventBus } from "../main.js"
axios.interceptors.request.use((config) => {
  EventBus.$emit("api-call-started")
  const token = localStorage.getItem("accessToken")
  config.headers.Authorization = `Bearer ${token}`
  return config
})

axios.interceptors.response.use(
  (res) => {
    EventBus.$emit("api-call-completed")
    return res
  },
  (error) => {
    EventBus.$emit("api-call-completed")
    return error.response.data
  }
)
let apiUrl = "https://api.swapna.org/api"

export default {
  getToken(input) {
    return axios.get(
      `${apiUrl}/Auth?passCode=${parseInt(input.passCode)}&accessToken=${encodeURIComponent(input.accessToken)}`
    )
  },
  subscribePlugin(input) {
    return axios.post(`${apiUrl}/Auth`, input)
  },
  subscribeMail(input) {
    return axios.post(`${apiUrl}/Auth/subscribe?email=${input.email}`)
  },
  verifyToken(input) {
    return axios.post(`${apiUrl}/Auth/verify?code=${input.code}&email=${input.email}`)
  },
  verifyAccessKey(input) {
    return axios.post(`${apiUrl}/Auth/verify-access`, input)
  },
  getMenus(issueType) {
    return axios.get(`${apiUrl}/TypeOfIssue?issueType=${issueType}`)
  },
  addReport(input) {
    return axios.post(`${apiUrl}/Reports`, input)
  },
  getReport() {
    return axios.get(`${apiUrl}/Reports?issueId=${localStorage.getItem("issueId")}`)
  },
  getReport1(id) {
    return axios.get(`${apiUrl}/Reports?issueId=${id}`)
  },
  getWeeklyReport() {
    return axios.get(`${apiUrl}/Reports/weekly-report?issueId=${localStorage.getItem("issueId")}`)
  },
  getHelplineNoByCN_ST(input) {
    return axios.get(`${apiUrl}/Helpline?country=${input.country}&state=${input.state}`)
  },
  addGetHelpData(input) {
    return axios.post(`${apiUrl}/GetHelp`, input)
  },
  addContactUsData(input) {
    return axios.post(`${apiUrl}/ContactUs`, input)
  },
  getAllNews(input) {
    return axios.get(`${apiUrl}/News?skip=${input.skip}&take=${input.take}`)
  },
  login(input) {
    return axios.post(`${apiUrl}/Auth/register`, input)
  },
  verifyOtp(input) {
    return axios.post(`${apiUrl}/Auth/verify-otp`, input)
  },
  assignDreamCollection(id) {
    return axios.post(`${apiUrl}/DreamArt/assign-dreamart?userId=${id}`)
  },
  getDreamCollection(id) {
    return axios.get(`${apiUrl}/DreamArt/dream-collection?userId=${id}`)
  },
  downloadDreamImage(imageName) {
    return axios.get(`${apiUrl}/DreamArt/download-dreamart?imageName=${imageName}`)
  },
  getDreamCollectionCount() {
    return axios.get(`${apiUrl}/DreamArt/dream-count`)
  },
}
